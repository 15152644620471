<template>
  <div>
    <!-- address section start -->
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <div class="row">
          <div class="col-md-3 ml-auto my-4 mr-n5">
            <label class="font-weight-bold"> Select Language </label>
            <b-form-select
              v-model="selected"
              :options="options"
            ></b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" v-if="selected === 'ENGLISH'">
            <h1 class="text-center mb-4 font-weight-bold">
              eParisheva Terms of Use
            </h1>
            <ul class="eb-useing-gumtree">
              <li>Last updated on:April 15, 2021</li>

              <li>
                By signing up for a eParisheva Account (as defined in Section 1)
                or by using any eParisheva Services (as defined below), you are
                agreeing to be bound by the following terms and conditions (the
                “Terms of Service”). As used in these Terms of Service, “we”,
                “us” and “eParisheva” means the applicable eParisheva
                Contracting Party (as defined in Section 4 below). The services
                offered by eParisheva under the Terms of Service include various
                products and services to help you sell goods and services to
                buyers, whether online (“Online Services”) by enabling you to
                create and build your own online store, in person (“POS
                Services”), or both. Any such services offered by eParisheva are
                referred to in these Terms of Services as the “Services”. Any
                new features or tools which are added to the current Services
                shall be also subject to the Terms of Service. eParisheva
                reserves the right to update and change the Terms of Service by
                posting updates and changes to the eParisheva website. You are
                advised to check the Terms of Service from time to time for any
                updates or changes that may impact you and if you do not accept
                such amendments, you must cease using the Services. You must
                read, agree with and accept all of the terms and conditions
                contained or expressly referenced in these Terms of Service
                before you may sign up for a eParisheva Account or use any
                eParisheva Service. Everyday language summaries are provided for
                convenience only and appear in bold under each section, but
                these summaries are not legally binding. Please read the Terms
                of Service, including any document referred to in these Terms of
                Service, for the complete picture of your legal requirements. By
                using eParisheva or any eParisheva services, you are agreeing to
                these terms. Be sure to occasionally check back for updates.
              </li>
              <h3>1. Account Terms</h3>

              <li>
                <i class="flaticon-checked"></i> To access and use the Services,
                you must register for a eParisheva account (“Account”) by
                providing your full legal name, current address, phone number, a
                valid email address, and any other information indicated as
                required. eParisheva may reject your application for an Account,
                or cancel an existing Account, for any reason, in our sole
                discretion.
              </li>
              <li>
                <i class="flaticon-checked"></i> You must be the older of: (i)
                18 years, or (ii) at least the age of majority in the
                jurisdiction where you reside and from which you use the
                Services to open an Account.
              </li>
              <li>
                <i class="flaticon-checked"></i> You confirm that you are
                receiving any Services provided by eParisheva for the purposes
                of carrying on a business activity and not for any personal,
                household or family purpose.
              </li>
              <li>
                <i class="flaticon-checked"></i> You acknowledge that eParisheva
                will use the email address/phone number you provide on opening
                an Account or as updated by you from time to time as the primary
                method for communication with you. You must monitor the primary
                Account email address you provide to eParisheva and your primary
                Account email address must be capable of both sending and
                receiving messages. Your email communications with eParisheva
                can only be authenticated if they come from your primary Account
                email address.
              </li>
              <li>
                <i class="flaticon-checked"></i> You are responsible for keeping
                your password secure. eParisheva cannot and will not be liable
                for any loss or damage from your failure to maintain the
                security of your Account and password.
              </li>
              <li>
                <i class="flaticon-checked"></i> You acknowledge that you are
                responsible for the creation and operation of your eParisheva
                Store.
              </li>
              <li>
                <i class="flaticon-checked"></i> eParisheva is not a
                marketplace. Any contract of sale through your eParisheva Store
                is directly between you and the buyer.
              </li>
              <li>
                <i class="flaticon-checked"></i> You are responsible for all
                activity and content such as photos, images, videos, graphics,
                written content, audio files, code, information, or data
                uploaded, collected, generated, stored, displayed, distributed,
                transmitted or exhibited on or in connection with your Account
                (“Materials”).
              </li>
              <li>
                <i class="flaticon-checked"></i> A breach or violation of any
                term in the Terms of Service, as determined in the sole
                discretion of eParisheva may result in an immediate termination
                of your Services.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i> You are responsible for your
                Account and any Materials you upload to the eParisheva Service.
                Remember that if you violate these terms we may cancel your
                service. If we need to reach you, we will send you an email.
              </li>
            </ul>
            <h3>2.Account Activation</h3>
            <h5 style="font-weight: bold">2.1 Store Owner</h5>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>The person signing up for the
                Service by opening an Account will be the contracting party
                (“Store Owner”) for the purposes of our Terms of Service and
                will be the person who is authorized to use any corresponding
                Account we may provide to the Store Owner in connection with the
                Service. You are responsible for ensuring that the name of the
                Store Owner (including the legal name of the company that owns
                the Store, if applicable) is clearly visible on the Store’s
                website.
              </li>
              <li>
                <i class="flaticon-checked"></i>If you are signing up for the
                Services on behalf of your employer, your employer shall be the
                Store Owner. If you are signing up for the Services on behalf of
                your employer, then you must use your employer-issued email
                address and you represent and warrant that you have the
                authority to bind your employer to our Terms of Service.
              </li>
              <li>
                <i class="flaticon-checked"></i>Your eParisheva Store can only
                be associated with one Store Owner. A Store Owner may have
                multiple eParisheva Stores. “Store” means the online store or
                physical retail location(s) associated with the Account.
              </li>
              <h5 style="font-weight: bold" class="mt-3">2.2 Staff Accounts</h5>
              <li>
                <i class="flaticon-checked"></i>Based on your eParisheva
                subscription plan, you can create one or more staff accounts
                (“Staff Accounts”) allowing other people to access the Account.
                With Staff Accounts, the Store Owner can set permissions and let
                other people work in their Account while determining the level
                of access by Staff Accounts to specific business information
                (for example, you can limit Staff Account access to sales
                information on the Reports page or prevent Staff Accounts from
                changing general store settings).
              </li>
              <li>
                <i class="flaticon-checked"></i>The Store Owner is responsible
                and liable for the acts, omissions and defaults arising from use
                of Staff Accounts in the performance of obligations under these
                Terms of Service as if they were the Store Owner’s own acts,
                omissions or defaults.
              </li>
              <li>
                <i class="flaticon-checked"></i>The Store Owner and the users
                under Staff Accounts are each referred to as a “eParisheva
                User”.
              </li>

              <h3 class="mt-4">3. General Conditions</h3>
              <li class="text-justify">
                You must read, agree with and accept all of the terms and
                conditions contained in these Terms of Service, including the
                Privacy Policy. Additionally, you acknowledge and agree to the
                Rules of Engagement for the Sale of COVID-19 Related Products if
                you sell such products.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>Technical support in respect of
                the Services is only provided to eParisheva Users.
              </li>
              <li>
                <i class="flaticon-checked"></i>You acknowledge and agree that
                eParisheva may amend these Terms of Service at any time by
                posting the relevant amended and restated Terms of Service
              </li>
              <li>
                <i class="flaticon-checked"></i>You may not use the eParisheva
                Services for any illegal or unauthorized purpose nor may you, in
                the use of the Service, violate any laws in your jurisdiction
                (including but not limited to copyright laws), the laws
                applicable to you in your customer’s jurisdiction.
              </li>
              <li>
                <i class="flaticon-checked"></i>You agree not to reproduce,
                duplicate, copy, sell, resell or exploit any portion of the
                Service, use of the Services, or access to the Services without
                the express written permission by eParisheva.
              </li>
              <li>
                <i class="flaticon-checked"></i>You shall not purchase search
                engine or other pay per click keywords (such as Google Ads), or
                domain names that use eParisheva or eParisheva trademarks and/or
                variations and misspellings thereof.
              </li>
              <li>
                <i class="flaticon-checked"></i>Questions about the Terms of
                Service should be sent to eParisheva Support..
              </li>
              <li>
                <i class="flaticon-checked"></i>You understand that your
                Materials (not including credit card information), may be
                transferred unencrypted and involve (a) transmissions over
                various networks; and (b) changes to conform and adapt to
                technical requirements of connecting networks or devices. Credit
                card information is always encrypted during transfer over
                networks.
              </li>
              <li>
                <i class="flaticon-checked"></i>The Services allow you to send
                certain communications to your customers by short message
                service (SMS) messaging (for example, sending order confirmation
                notifications via SMS) (the "SMS Services"). You will only use
                the SMS Services in compliance with these Terms of Service.
              </li>
              <li>
                <i class="flaticon-checked"></i>You acknowledge and agree that
                your use of the Services, including information transmitted to
                or stored by eParisheva, is governed by its privacy policy.
              </li>
              <li>
                <i class="flaticon-checked"></i>The Terms of Service may be
                available in languages other than English. To the extent of any
                inconsistencies or conflicts between these English Terms of
                Service and eParisheva’s Terms of Service available in another
                language, the most current English version of the Terms of
                Service will prevail. Any disputes arising out of these Terms of
                Service will be resolved in English unless otherwise determined
                by eParisheva (acting in its sole discretion) or as required by
                applicable law.
              </li>
              <li>
                <i class="flaticon-checked"></i>All the terms and provisions of
                the Terms of Service shall be binding upon and inure to the
                benefit of the parties to the Terms of Service and to their
                respective heirs, successors, permitted assigns and legal
                representatives. eParisheva shall be permitted to assign these
                Terms of Service without notice to you or consent from you. You
                shall have no right to assign or otherwise transfer the Terms of
                Service, or any of your rights or obligations hereunder, to any
                third party without eParisheva’s prior written consent, to be
                given or withheld in eParisheva’s sole discretion.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i>The eParisheva service belongs
                to us. You are not allowed to rip it off or use it for any
                illegal or sketchy purpose. If you or your employees or
                contractors use eParisheva’s APIs, you will comply with our API
                terms.
              </li>
              <li>
                <i class="flaticon-checked"></i>Your Materials may be
                transferred unencrypted and may be altered, but credit card
                information is always encrypted.
              </li>
            </ul>
            <h3 class="my-4">4. eParisheva Rights</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> We reserve the right to modify
                or terminate the Services for any reason, without notice at any
                time. Not all Services and features are available in every
                jurisdiction and we are under no obligation to make any Services
                or features available in any jurisdiction.
              </li>
              <li>
                <i class="flaticon-checked"></i> We reserve the right to refuse
                service to anyone for any reason at any time.
              </li>
              <li>
                <i class="flaticon-checked"></i> We may, but have no obligation
                to, remove Materials and suspend or terminate Accounts if we
                determine in our sole discretion that the goods or services
                offered via a Store, or the Materials uploaded or posted to a
                Store, violate our Terms of Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> Verbal or written abuse of any
                kind (including threats of abuse or retribution) of any
                eParisheva customer, eParisheva employee, member, or officer
                will result in immediate Account termination.
              </li>
              <li>
                <i class="flaticon-checked"></i> eParisheva does not pre-screen
                Materials and it is in our sole discretion to refuse or remove
                any Materials from the Service, including your Store.
              </li>
              <li>
                <i class="flaticon-checked"></i> We reserve the right to provide
                our services to your competitors and make no promise of
                exclusivity in any particular market segment. You further
                acknowledge and agree that eParisheva employees and contractors
                may also be eParisheva customers/merchants and that they may
                compete with you, although they may not use your Confidential
                Information in doing so.
              </li>
              <li>
                <i class="flaticon-checked"></i> In the event of a dispute
                regarding Account ownership, we reserve the right to request
                documentation to determine or confirm Account ownership.
                Documentation may include, but is not limited to, a scanned copy
                of your business license, government issued photo ID, the last
                four digits of the credit card on file, your status as an
                employee of an entity, etc.
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva retains the right to
                determine, in our sole judgment, rightful Account ownership and
                transfer an Account to the rightful Store Owner. If we are
                unable to reasonably determine the rightful Store Owner, without
                prejudice to our other rights and remedies, eParisheva reserves
                the right to temporarily disable an Account until resolution has
                been determined between the disputing parties.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i>We can modify, cancel or refuse
                the service at anytime.
              </li>
              <li>
                <i class="flaticon-checked"></i>In the event of an ownership
                dispute over eParisheva account, we can freeze the account or
                transfer it to the rightful owner, as determined by us.
              </li>
            </ul>
            <h3>5. Confidentiality</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>“Confidential Information” shall
                include, but shall not be limited to, any and all information
                associated with a party’s business and not publicly known,
                including specific business information, technical processes and
                formulas, software, customer lists, prospective customer lists,
                names, addresses and other information regarding customers and
                prospective customers, product designs, sales, costs (including
                any relevant processing fees), price lists, and other
                unpublished financial information, business plans and marketing
                data, and any other confidential and proprietary information,
                whether or not marked as confidential or proprietary.
                eParisheva’s Confidential Information includes all information
                that you receive relating to us, or to the Services, that is not
                known to the general public including information related to our
                security program and practices.
              </li>
              <li>
                <i class="flaticon-checked"></i> Each party agrees to use the
                other party’s Confidential Information solely as necessary for
                performing its obligations under these Terms of Service and in
                accordance with any other obligations in these Terms of. Each
                party agrees that it shall take all reasonable steps, at least
                substantially equivalent to the steps it takes to protect its
                own proprietary information, to prevent the duplication,
                disclosure or use of any such Confidential Information.
                Confidential Information shall not include any information that
                the receiving party can prove: (A) was already in the public
                domain, or was already known by or in the possession of the
                receiving party, at the time of disclosure of such information;
                (B) is independently developed by the receiving party without
                use of or reference to the other party’s Confidential
                Information, and without breaching any provisions of these Terms
                of Service; or (C) is thereafter rightly obtained by the
                receiving party from a source other than the disclosing party
                without breaching any provision of these Terms of Service.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i>Both you and eParishevaagree to
                use the Confidential Information of the other only to perform
                the obligations in these Terms of Service.
              </li>
              <li>
                <i class="flaticon-checked"></i> Confidential Information must
                be protected and respected.
              </li>
            </ul>
            <h3 class="mt-4">6. Limitation of Liability</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>You expressly understand and
                agree that, to the extent permitted by applicable laws,
                eParisheva shall not be liable for any direct, indirect,
                incidental, special, consequential or exemplary damages,
                including but not limited to, damages for loss of profits,
                goodwill, use, data or other intangible losses resulting from
                the use of or inability to use the Service.
              </li>
              <li>
                <i class="flaticon-checked"></i>To the extent permitted by
                applicable laws, in no event shall eParisheva’s or our suppliers
                be liable for lost profits or any special, incidental or
                consequential damages arising out of or in connection with our
                site, our Services or these Terms of Service (however arising
                including negligence). You agree to indemnify and hold us and
                (as applicable) our parent, subsidiaries, affiliates, eParisheva
                partners, officers, directors, agents, employees, and suppliers
                harmless from any claim or demand, including reasonable
                attorneys’ fees, made by any third party due to or arising out
                of your breach of these Terms of Service or your violation of
                any law or the rights of a third party.
              </li>
              <li>
                <i class="flaticon-checked"></i>Your use of the Services is at
                your sole risk. The Services are provided on an “as is” and “as
                available” basis without any warranty or condition, express,
                implied or statutory.
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva does not warrant that
                the Services will be uninterrupted, timely, secure, or
                error-free.
              </li>
              <li>
                <i class="flaticon-checked"></i>
                eParisheva does not warrant that the results that may be
                obtained from the use of the Services will be accurate or
                reliable.
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva is not responsible
                for any of your tax obligations or liabilities related to the
                use of eParisheva’s Services.
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva does not warrant that
                the quality of any products, services, information, or other
                materials purchased or obtained by you through the Services will
                meet your expectations, or that any errors in the Services will
                be corrected.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i>We are not responsible if you
                break the law, breach this agreement or go against the rights of
                a third party, especially if you get sued.
              </li>
              <li>
                <i class="flaticon-checked"></i>Service is “as is” so it may
                have errors or interruptions and we provide no warranties and
                our liability is limited.
              </li>
            </ul>

            <h3 class="mt-4">8. Intellectual Property and Customer Content</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>We do not claim any intellectual
                property rights over the Materials you provide to the eParisheva
                Service. All Materials you upload to your eParisheva Store
                remains yours. You can remove your eParisheva Store at any time
                by deleting your Account.
              </li>
              <li>
                <i class="flaticon-checked"></i>By uploading Materials, you
                agree: (a) to allow other internet users to view the Materials
                you post publicly to your Store; (b) to allow eParisheva to
                store, and in the case of Materials you post publicly, display
                and use your Materials; and (c) that eParisheva can, at any
                time, review and delete all the Materials submitted to its
                Service, although eParisheva is not obligated to do so.
              </li>
              <li>
                <i class="flaticon-checked"></i> You retain ownership over all
                Materials that you upload to the Store; however, by making your
                Store public, you agree to allow others to view Materials that
                you post publicly to your Store. You are responsible for
                compliance of the Materials with any applicable laws or
                regulations.
              </li>
              <li>
                <i class="flaticon-checked"></i> eParisheva shall have the
                non-exclusive right and license to use the names, trademarks,
                service marks and logos associated with your Store to promote
                the Service.
              </li>
              <li>
                <h3>WHICH MEANS</h3>
                <i class="flaticon-checked"></i>Anything you upload remains
                yours and is your responsibility.
              </li>
            </ul>
            <h3 class="mt-4">9. Cancellation and Termination</h3>

            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> You may cancel your Account and
                terminate the Terms of Service at any time by contacting
                eParisheva Support and then following the specific instructions
                indicated to you in eParisheva’s response.
              </li>
              <li>
                2. Upon termination of the Services by either party for any
                reason:
              </li>
              <li>
                1. eParishevawill cease providing you with the Services and you
                will no longer be able to access your Account;
              </li>
              <li>
                2. unless otherwise provided in the Terms of Service, you will
                not be entitled to any refunds of any Fees.
              </li>
              <li>
                3. any outstanding balance owed to eParisheva for your use of
                the Services through the effective date of such termination will
                immediately become due and payable in full; and
              </li>
              <li>4. your Store website will be taken offline.</li>
              <li>
                <i class="flaticon-checked"></i> If at the date of termination
                of the Service, there are any outstanding Fees owing by you, you
                will receive one final invoice via email. Once that invoice has
                been paid in full, you will not be charged again.
              </li>
              <li>
                <i class="flaticon-checked"></i> We reserve the right to modify
                or terminate the eParishevaService, the Terms of Service and/or
                your Account for any reason, without notice at any time (unless
                otherwise required by applicable law). Termination of the Terms
                of Service shall be without prejudice to any rights or
                obligations which arose prior to the date of termination.
              </li>
              <li>
                <i class="flaticon-checked"></i>Fraud: Without limiting any
                other remedies, eParishevamay suspend or terminate your Account
                if we suspect that you (by conviction, settlement, insurance or
                escrow investigation, or otherwise) have engaged in fraudulent
                activity in connection with the use of the Services.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i> To initiate a termination
                contact Support. eParishevawill respond with specific
                information regarding the termination process for your account.
                Once termination is confirmed, domains purchased through
                eParishevawill no longer be automatically renewed. If you cancel
                in the middle of your billing cycle, you’ll have one last email
                invoice.
              </li>
              <li>
                <i class="flaticon-checked"></i> We may change or terminate your
                account at any time. Any fraud and we will suspend or terminate
                your account.
              </li>
            </ul>
            <h3 class="mt-4">10. Modifications to the Service and Prices</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> Prices for using the Services
                are subject to change upon 30 days’ notice from eParisheva.
              </li>

              <li>
                <i class="flaticon-checked"></i> eParisheva reserves the right
                at any time, and from time to time, to modify or discontinue,
                the Services (or any part thereof) with or without notice
                (unless otherwise required by applicable law).
              </li>
              <li>
                <i class="flaticon-checked"></i> eParisheva shall not be liable
                to you or to any third party for any modification, price change,
                suspension or discontinuance of the Service.
              </li>

              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i> We may change or discontinue
                the service at anytime, without liability.
              </li>
            </ul>
            <h3 class="mt-4">11. Privacy & Data Protection</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> eParisheva is firmly committed
                to protecting the privacy of your personal information and the
                personal information of your customers. By using the Service,
                you acknowledge and agree that eParisheva’s collection, usage
                and disclosure of this personal information is governed by our
                Privacy Policy.
              </li>
            </ul>
          </div>
        </div>
        <div class="row" v-if="selected === 'BANGLA'">
          <div class="col-sm-12">
            <h2 class="text-center mb-5">eParisheva সেবা পাওয়ার শর্ত</h2>
            <ul class="eb-useing-gumtree">
              <li>সর্বশেষ আপডেট হয়েছে: 15 এপ্রিল, 2021</li>
              <li>
                কোনও ই-পরিষেবা অ্যাকাউন্টে সাইন আপ করে (বিভাগ 1 এ সংজ্ঞায়িত করা
                হয়েছে) অথবা ই- পরিষেবা ব্যবহার করে (নীচে সংজ্ঞায়িত), আপনি
                নিম্নলিখিত শর্তাবলী এবং শর্তাবলী("পরিষেবার শর্তাবলী") দ্বারা
                আবদ্ধ হতে সম্মত হচ্ছেন। এই পরিষেবার শর্তাদি হিসাবে ব্যবহৃত
                হয়েছে, "আমরা", "আমাদের" এবং "ই-পরিষেবা" অর্থ প্রযোজ্য ই-পরিষেবা
                চুক্তিবদ্ধ পার্টি (নীচের বিভাগে চার হিসাবে সংজ্ঞায়িত)। পরিষেবার
                শর্তাদির অধীনে ই-পরিষেবা দ্বারা প্রদত্ত পরিষেবাদিগুলিতে
                ব্যক্তিগতভাবে আপনার নিজস্ব অনলাইন স্টোর তৈরি এবং তৈরি করতে সক্ষম
                হয়ে অনলাইনে ("অনলাইন পরিষেবাদিগুলি") আপনাকে ক্রেতাদের কাছে পণ্য
                ও পরিষেবা বিক্রয় করতে সহায়তা করার জন্য বিভিন্ন পণ্য এবং
                পরিষেবাদি অন্তর্ভুক্ত রয়েছে (“পোস পরিষেবাদি "), বা উভয়ই
                ই-পরিষেবা দ্বারা প্রদত্ত এই জাতীয় যে কোনও পরিষেবাদি এই পরিষেবার
                শর্তাদি "পরিষেবাদি" হিসাবে উল্লেখ করা হয়েছে। বর্তমান
                পরিষেবাদিতে যুক্ত হওয়া কোনও নতুন বৈশিষ্ট্য বা সরঞ্জামগুলিও
                পরিষেবার শর্তাদি সাপেক্ষে।ই-পরিষেবা ওয়েবসাইটের আপডেট এবং
                পরিবর্তন পোস্ট করে পরিষেবার শর্তাদি আপডেট এবং পরিবর্তন করার
                অধিকার সংরক্ষণ করে। আপনাকে প্রভাবিত করতে পারে এমন কোনও আপডেট বা
                পরিবর্তনগুলির জন্য আপনাকে সময় সময় পরিষেবার শর্তাদি পরীক্ষা
                করার পরামর্শ দেওয়া হয় এবং যদি আপনি এই ধরনের সংশোধনী গ্রহণ না
                করেন তবে আপনাকে অবশ্যই পরিষেবাগুলি ব্যবহার বন্ধ করতে হবে। আপনি
                ই-পরিষেবা অ্যাকাউন্টে সাইন আপ করতে বা কোনও ই-পরিষেবা পরিষেবা
                ব্যবহার করার আগে আপনাকে অবশ্যই পরিষেবার শর্তাদির মধ্যে থাকা
                সমস্ত শর্তাদি পড়ার সাথে একমত হতে হবে এবং স্বীকার করতে হবে।
                প্রতিদিনের ভাষার সংক্ষিপ্তসারগুলি কেবল সুবিধার জন্য সরবরাহ করা
                হয় এবং প্রতিটি বিভাগের অধীনে সাহসী ভাবে উপস্থিত হয় তবে এই
                সংক্ষিপ্তগুলি আইনত বাধ্যতামূলক নয়। আপনার আইনী প্রয়োজনীয়তার
                সম্পূর্ণ চিত্রের জন্য দয়া করে পরিষেবার শর্তাদি, পরিষেবার
                শর্তাদিগুলিতে উল্লিখিত কোনও দলিল সহ পড়ুন। ই-পরিষেবা বা কোনও
                ই-পরিষেবা পরিষেবা ব্যবহার করে আপনি এই শর্তাদিতে সম্মতি দিচ্ছেন।
                আপডেটের জন্য মাঝে মাঝে ফিরে চেক করতে ভুলবেন না।
              </li>
              <h3>1. অ্যাকাউন্ট শর্তাদি</h3>

              <li>
                <i class="flaticon-checked"></i> পরিষেবাগুলিতে অ্যাক্সেস এবং
                ব্যবহার করতে আপনাকে অবশ্যই আপনার সম্পূর্ণ আইনী নাম, বর্তমান
                ঠিকানা, ফোন নম্বর, একটি বৈধ ইমেল ঠিকানা এবং প্রয়োজনীয় হিসাবে
                নির্দেশিত অন্য কোনও তথ্য সরবরাহ করে একটি ই-পরিষেবা অ্যাকাউন্টের
                জন্য ("অ্যাকাউন্ট") নিবন্ধন করতে হবে। ই-পরিষেবা কোনও একাউন্টের
                জন্য আপনার আবেদন প্রত্যাখ্যান করতে পারে, বা আমাদের একমাত্র
                বিবেচনার ভিত্তিতে কোনও কারণে কোনও বিদ্যমান অ্যাকাউন্ট বাতিল করতে
                পারে।
              </li>
              <li>
                <i class="flaticon-checked"></i> আপনার অবশ্যই বয়স্ক হতে হবে:
                (i) ১৮ বছর বা (ii) আপনি যেখানে থাকেন সেখানকার অঞ্চলে কমপক্ষে
                সংখ্যাগরিষ্ঠের বয়স এবং আপনি অ্যাকাউন্ট খোলার জন্য পরিষেবাগুলি
                ব্যবহার করেন।
              </li>
              <li>
                <i class="flaticon-checked"></i> আপনি নিশ্চিত করেন যে আপনি কোনও
                ব্যবসায়িক ক্রিয়াকলাপ চালিয়ে যাওয়ার উদ্দেশ্যে এবং কোনও
                ব্যক্তিগত, পরিবার বা পারিবারিক উদ্দেশ্যে নয়,ই-পরিষেবা দেওয়া
                কোনও পরিষেবা পেয়ে যাচ্ছেন।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি স্বীকার করেছেন যে ই-পরিষেবা
                আপনার সাথে যোগাযোগের জন্য প্রাথমিক পদ্ধতি হিসাবে কোনও অ্যাকাউন্ট
                খোলার সময় বা আপনার সময়ে সময়ে আপডেট হওয়া ইমেল ঠিকানা / ফোন
                নম্বর ব্যবহার করবে। আপনাকে অবশ্যই ই-পরিষেবা প্রদত্ত প্রাথমিক
                অ্যাকাউন্ট ইমেল ঠিকানাটি পর্যবেক্ষণ করতে হবে এবং আপনার প্রাথমিক
                অ্যাকাউন্ট ইমেল ঠিকানা অবশ্যই বার্তা প্রেরণ এবং গ্রহণ করতে সক্ষম
                হতে হবে। ই-পরিষেবা সহ আপনার ইমেল যোগাযোগগুলি কেবলমাত্র যদি আপনার
                প্রাথমিক অ্যাকাউন্ট ইমেল ঠিকানা থেকে আসে তবে তা প্রমাণীকরণযোগ্য।
              </li>
              <li>
                <i class="flaticon-checked"></i> আপনি নিজের পাসওয়ার্ড সুরক্ষিত
                রাখার জন্য দায়বদ্ধ। আপনার অ্যাকাউন্ট এবং পাসওয়ার্ডের সুরক্ষা
                বজায় রাখতে ব্যর্থতা থেকে কোনও ক্ষতি বা ক্ষতির জন্য ই-পরিষেবা
                দায়বদ্ধ হতে পারে না।
              </li>
              <li>
                <i class="flaticon-checked"></i> আপনি স্বীকার করেছেন যে আপনি
                আপনার ই-পরিষেবা স্টোর তৈরি ও পরিচালনা করার জন্য দায়বদ্ধ।
              </li>
              <li>
                <i class="flaticon-checked"></i> ই-পরিষেবা কোনও মার্কেটপ্লেস
                নয়। আপনার ই-পরিষেবা স্টোরের মাধ্যমে বিক্রয়ের কোনও চুক্তি
                সরাসরি আপনার এবং ক্রেতার মধ্যে হতে পারে ।
              </li>
              <li>
                <i class="flaticon-checked"></i> ফটো, চিত্র, ভিডিও, গ্রাফিক্স,
                লিখিত সামগ্রী, অডিও ফাইল, কোড, তথ্য, বা ডেটা আপলোড, সংগৃহীত,
                উৎপন্ন , সঞ্চয়, প্রদর্শন, বিতরণ, সংক্রমণ বা প্রদর্শিত বা
                প্রদর্শিত হিসাবে যেমন সমস্ত ক্রিয়াকলাপ এবং সামগ্রীর জন্য আপনি
                দায়বদ্ধ আপনার অ্যাকাউন্টের সাথে সংযোগ ("উপাদানগুলি")।
              </li>
              <li>
                <i class="flaticon-checked"></i>পরিষেবার শর্তাদির কোনও শর্ত
                লঙ্ঘন, ই-পরিষেবা একমাত্র বিবেচনার ভিত্তিতে নির্ধারিতভাবে আপনার
                পরিষেবাদিগুলির তাৎক্ষণিক অবসান হতে পারে।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i> আপনি আপনার অ্যাকাউন্ট এবং যে
                কোনও উপাদান আপনি ই-পরিষেবা পরিষেবাতে আপলোড করেন তার জন্য আপনি
                দায়বদ্ধ। মনে রাখবেন আপনি যদি এই শর্তাদি লঙ্ঘন করেন তবে আমরা
                আপনার পরিষেবা বাতিল করতে পারি।
              </li>
              <li>
                <i class="flaticon-checked"></i>আমাদের যদি আপনার কাছে পৌঁছানোর
                দরকার হয় তবে আমরা আপনাকে একটি ইমেল পাঠাব।
              </li>
            </ul>
            <h3>2. অ্যাকাউন্ট অ্যাক্টিভেশন</h3>
            <h5 style="font-weight: bold">2.1 স্টোরের মালিক</h5>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>অ্যাকাউন্ট খোলার মাধ্যমে যে
                ব্যক্তি পরিষেবার জন্য সাইন আপ করবেন সে হ'ল আমাদের পরিষেবার
                শর্তাদির উদ্দেশ্যে চুক্তি পক্ষ ("স্টোরের মালিক") হবেন এবং সেই
                ব্যক্তি হবেন যাকে আমরা সরবরাহ করতে পারি যে কোনও সম্পর্কিত
                অ্যাকাউন্ট ব্যবহারের জন্য অনুমোদিত পরিষেবাটির সাথে সম্পর্কিত
                মালিককে সঞ্চয় করুন। স্টোরের মালিকের নাম (স্টোরের মালিকানাধীন
                সংস্থার আইনী নাম সহ, যদি প্রযোজ্য হয় তবে) স্টোরের ওয়েবসাইটে
                স্পষ্টভাবে দৃশ্যমান তা নিশ্চিত করার জন্য আপনি দায়বদ্ধ।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি যদি আপনার নিয়োগকর্তার
                পক্ষে পরিষেবাগুলিতে সাইন আপ করেন তবে আপনার নিয়োগকর্তা স্টোর
                মালিক হবেন। আপনি যদি আপনার নিয়োগকর্তার পক্ষে পরিষেবাগুলির জন্য
                সাইন আপ করছেন, তবে আপনাকে অবশ্যই আপনার নিয়োগকর্তা-ইস্যু করা
                ইমেল ঠিকানাটি ব্যবহার করতে হবে এবং আপনার নিয়োগকর্তাকে আমাদের
                পরিষেবার শর্তাদির সাথে বেঁধে রাখার অধিকার আপনার রয়েছে বলে আপনি
                প্রতিনিধিত্ব ও ওয়্যারেন্টি দিতে পারেন।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনার ই-পরিষেবা স্টোর কেবল একটি
                স্টোর মালিকের সাথে সম্পর্কিত হতে পারে। স্টোরের মালিকের একাধিক
                ই-পরিষেবা স্টোর থাকতে পারে। "স্টোর" অর্থ অ্যাকাউন্টের সাথে
                সম্পর্কিত অনলাইন স্টোর বা শারীরিক খুচরা অবস্থান (গুলি)।
              </li>
              <h5 style="font-weight: bold" class="mt-3">
                2.2 স্টাফ অ্যাকাউন্ট
              </h5>
              <li>
                <i class="flaticon-checked"></i> আপনার ই-পরিষেবা সাবস্ক্রিপশন
                পরিকল্পনার ভিত্তিতে, আপনি এক বা একাধিক স্টাফ অ্যাকাউন্ট তৈরি
                করতে পারেন ("স্টাফ অ্যাকাউন্টস") যাতে অন্য লোকদের অ্যাকাউন্টে
                অ্যাক্সেস করতে পারে। স্টাফ অ্যাকাউন্টগুলির সাথে স্টোরের মালিক
                নির্দিষ্ট ব্যবসায়ের তথ্যে স্টাফ অ্যাকাউন্ট দ্বারা অ্যাক্সেসের
                স্তর নির্ধারণ করার সময় অনুমতিগুলি নির্ধারণ করতে এবং অন্যান্য
                ব্যক্তিকে তাদের অ্যাকাউন্টে কাজ করতে দেয় (উদাহরণস্বরূপ, আপনি
                রিপোর্ট পৃষ্ঠাগুলিতে বিক্রয় তথ্যে স্টাফ অ্যাকাউন্ট অ্যাক্সেসকে
                সীমাবদ্ধ করতে পারেন বা প্রতিরোধ করতে পারেন সাধারণ স্টোর সেটিংস
                পরিবর্তন করে স্টাফ অ্যাকাউন্ট)।
              </li>
              <li>
                <i class="flaticon-checked"></i>স্টোর মালিক এই পরিষেবার শর্তাদির
                অধীনে দায়বদ্ধতার কার্য সম্পাদনে স্টাফ অ্যাকাউন্টের ব্যবহার থেকে
                অদ্ভুত ক্রিয়াকলাপ, ছাড় এবং ডিফল্টের জন্য দায়বদ্ধ এবং
                দায়বদ্ধ, যেন তারা স্টোর মালিকের নিজস্ব কাজ, বাদ দেওয়া বা
                খেলাপি।
              </li>
              <li>
                <i class="flaticon-checked"></i>স্টোরের মালিক এবং স্টাফ
                অ্যাকাউন্টের আওতাধীন ব্যবহারকারীদের প্রত্যেককে "ই-পরিষেবা
                ব্যবহারকারী" হিসাবে উল্লেখ করা হয়।
              </li>

              <h3 class="mt-4">3. সাধারণ অবস্থা</h3>
              <li class="text-justify">
                আপনাকে অবশ্যই এই গোপনীয়তা নীতি সহ পরিষেবার শর্তাদির মধ্যে থাকা
                সমস্ত শর্তাদি পড়তে হবে, একমত হতে হবে এবং মেনে নিতে হবে।
                অতিরিক্তভাবে, আপনি যদি এই জাতীয় পণ্য বিক্রি করেন তবে আপনি
                COVID-19 সম্পর্কিত পণ্য বিক্রয়ের জন্য প্রবৃদ্ধির বিধিগুলিকে
                স্বীকার ও সম্মতি দিচ্ছেন।
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>পরিষেবাদিগুলির ক্ষেত্রে
                প্রযুক্তিগত সহায়তা কেবলমাত্র ই-পরিষেবা ব্যবহারকারীদের জন্য
                সরবরাহ করা হয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি স্বীকার করেন এবং সম্মত হন
                যে ই-পরিষেবা যে কোনও সময় প্রাসঙ্গিক সংশোধিত ও পুনর্নির্দিষ্ট
                পরিষেবার শর্তাদি পোস্ট করে এই পরিষেবার শর্তাদি সংশোধন করতে পারে।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি ই-পরিষেবা পরিষেবাগুলি কোনও
                অবৈধ বা অননুমোদিত উদ্দেশ্যে ব্যবহার করতে পারবেন না বা আপনি
                পরিষেবাটি ব্যবহারের ক্ষেত্রে আপনার এখতিয়ারের কোনও আইন (কপিরাইট
                আইন সহ তবে সীমাবদ্ধ নয়), আপনার গ্রাহকের ক্ষেত্রে প্রযোজ্য আইন
                লঙ্ঘন করতে পারবেন এখতিয়ার।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি ই-পরিষেবা দ্বারা প্রকাশিত
                লিখিত অনুমতি ব্যতীত পরিষেবাটির কোনও অংশের পুনঃ উৎপাদন সদৃশ,
                অনুলিপি, বিক্রয়, পুনরায় বিক্রয় , পরিষেবা ব্যবহার বা পরিষেবাতে
                অ্যাক্সেস না করার বিষয়ে সম্মত হন।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি ক্লিক কীওয়ার্ডগুলি (যেমন
                গুগল বিজ্ঞাপন), বা ডোমেনের নাম যা ই-পরিষেবা বা ই-পরিষেবা
                ট্রেডমার্ক এবং / অথবা তারতম্য এবং ভুল বানান ব্যবহার করে তা
                অনুসন্ধান ইঞ্জিন বা অন্যান্য বেতন ক্রয় করবেন না।পরিষেবার
                শর্তাদি সম্পর্কে প্রশ্নগুলি ই-পরিষেবা সাপোর্টে প্রেরণ করা উচিত।
                আপনি বুঝতে পেরেছেন যে আপনার সামগ্রীগুলি (ক্রেডিট কার্ডের তথ্য সহ
                নয়), এনক্রিপ্ট না করে স্থানান্তরিত হতে পারে এবং (ক) বিভিন্ন
                নেটওয়ার্কে স্থানান্তরিত হতে পারে; এবং (খ) সংযোগকারী নেটওয়ার্ক
                বা ডিভাইসগুলির প্রযুক্তিগত প্রয়োজনীয়তার সাথে খাপ খাইয়ে
                নেওয়ার জন্য পরিবর্তনগুলি। নেটওয়ার্কগুলির ওপরে স্থানান্তরকালে
                ক্রেডিট কার্ডের তথ্য সর্বদা এনক্রিপ্ট করা হয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>পরিষেবাগুলি আপনাকে গ্রাহকদের
                সংক্ষিপ্ত বার্তা পরিষেবা (এসএমএস) বার্তাপ্রেরণের মাধ্যমে
                নির্দিষ্ট যোগাযোগ প্রেরণের অনুমতি দেয় (উদাহরণস্বরূপ, এসএমএসের
                মাধ্যমে অর্ডার কনফার্মেশন নোটিফিকেশন প্রেরণ) ("এসএমএস
                পরিষেবাদি")। আপনি কেবল পরিষেবার শর্তাদি মেনে এসএমএস পরিষেবা
                ব্যবহার করবেন।
              </li>
              <li>
                <i class="flaticon-checked"></i> আপনি স্বীকার করেছেন এবং সম্মত
                হন যে আপনার পরিষেবাগুলির ব্যবহার, ই-পরিষেবা দ্বারা প্রেরণ বা
                সঞ্চিত তথ্য সহ, এর গোপনীয়তা নীতি দ্বারা পরিচালিত হয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>পরিষেবার শর্তাদি ইংরেজি ব্যতীত
                অন্য ভাষায় উপলব্ধ হতে পারে। এই ইংরেজী পরিষেবার শর্তাদি এবং
                ই-পরিষেবা পরিষেবার শর্তাদি অন্য কোনও ভাষায় উপলব্ধ যে কোনও
                অসঙ্গতি বা দ্বন্দ্বের মাত্রায়, পরিষেবার শর্তাদির সর্বাধিকতম
                ইংরেজী সংস্করণটি বিরাজ করবে। এই পরিষেবার শর্তাদি থেকে উদ্ভূত যে
                কোনও বিরোধ ইংরেজী ভাষায় সমাধান করা হবে যদি না ই ই-পরিষেবা
                দ্বারা নির্ধারিত হয় (এর নিজস্ব বিবেচনার ভিত্তিতে অভিনয় করে) বা
                প্রযোজ্য আইনের দ্বারা প্রয়োজনীয় না হয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>পরিষেবার শর্তাদির সমস্ত শর্তাদি
                এবং বিধানগুলি পরিষেবার শর্তাদি এবং তাদের নিজ নিজ উত্তরাধিকারী,
                উত্তরসূরীদের অনুমতিপ্রাপ্ত কার্যভার এবং আইনী প্রতিনিধিদের পক্ষের
                সুবিধার জন্য বাধ্যতামূলক এবং সুনিশ্চিত হবে।ই-পরিষেবা আপনাকে কোনও
                বিজ্ঞপ্তি বা আপনার সম্মতি ছাড়াই এই পরিষেবার শর্তাদি নির্ধারিত
                করার অনুমতি পাবে। ই-পরিষেবা একমাত্র বিবেচনার ভিত্তিতে প্রদত্ত বা
                রোধ করার জন্য আপনাকে ই-পরিষেবা পূর্ব লিখিত সম্মতি ব্যতীত কোনও
                তৃতীয় পক্ষের কাছে পরিষেবার শর্তাদি বা অন্য কোনও অধিকার বা
                বাধ্যবাধকতা অর্পণ বা হস্তান্তর করার কোনও অধিকার থাকবে না।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা পরিষেবাটি আমাদেরই।
                আপনাকে এটি ছিঁড়ে ফেলতে বা কোনও অবৈধ উদ্দেশ্যে ব্যবহার করার
                অনুমতি নেই। আপনি বা আপনার কর্মচারী বা ঠিকাদারগণ যদি ই-পরিষেবা
                এপিআই ব্যবহার করেন তবে আপনি আমাদের এপিআই এর শর্তাদি মেনে চলবেন।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনার সামগ্রীগুলি এনক্রিপ্ট না
                করে স্থানান্তরিত হতে পারে এবং পরিবর্তিত হতে পারে, তবে ক্রেডিট
                কার্ডের তথ্য সর্বদা এনক্রিপ্ট থাকে।
              </li>
            </ul>
            <h3 class="my-4">4. ই-পরিষেবা অধিকার</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>আমরা কোনও সময়ে বিজ্ঞপ্তি ছাড়াই
                কোনও কারণে পরিষেবাদি সংশোধন বা সমাপ্ত করার অধিকার সংরক্ষণ করি।
                সমস্ত পরিষেবাদি এবং বৈশিষ্ট্য প্রতিটি এখতিয়ারে উপলব্ধ নয় এবং
                আমাদের কোনও দায়বদ্ধতায় কোনও পরিষেবা বা বৈশিষ্ট্য উপলব্ধ করার
                কোনও বাধ্যবাধকতা নেই।
              </li>
              <li>
                <i class="flaticon-checked"></i>যে কোনও সময়ে যে কোনও সময়ে যে
                কোনও কারণে পরিষেবা প্রত্যাখ্যান করার অধিকার আমরা সংরক্ষণ করি।
              </li>
              <li>
                <i class="flaticon-checked"></i> আমাদের যদি কোনও স্টোরের মাধ্যমে
                প্রদত্ত পণ্য বা পরিষেবাগুলি বা কোনও স্টোরগুলিতে আপলোড করা বা
                পোস্ট করা সামগ্রীর দ্বারা আমাদের পরিষেবার শর্তাদি লঙ্ঘন করা হয়
                তবে যদি আমাদের নিজস্ব বিবেচনার ভিত্তিতে নির্ধারণ করা হয় তবে
                আমাদের উপাদানগুলি সরিয়ে ফেলা এবং অ্যাকাউন্টগুলি স্থগিত বা বন্ধ
                করার কোনও বাধ্যবাধকতা থাকতে পারে না ।
              </li>
              <li>
                <i class="flaticon-checked"></i>যে কোনও ই-পরিষেবা গ্রাহক,
                ই-পরিষেবা কর্মচারী, সদস্য, বা অফিসারের যেকোন ধরণের (অপব্যবহার বা
                প্রতিশোধের হুমকিসহ) মৌখিক বা লিখিত অপব্যবহারের ফলে তাত্ক্ষণিক
                অ্যাকাউন্ট সমাপ্তি ঘটবে।
              </li>
              <li>
                <i class="flaticon-checked"></i> ই-পরিষেবা প্রি-স্ক্রিন
                উপকরণগুলি দেয় না এবং আপনার স্টোর সহ পরিষেবা থেকে কোনও সামগ্রী
                প্রত্যাখ্যান করা বা অপসারণ করা আমাদের নিজস্ব বিবেচনার মধ্যে
                রয়েছে।
              </li>
              <li>
                <i class="flaticon-checked"></i>আমরা আপনার প্রতিযোগীদের আমাদের
                পরিষেবা সরবরাহ করার অধিকার সংরক্ষণ করি এবং কোনও নির্দিষ্ট বাজার
                বিভাগে এক্সক্লুসিভিটির কোনও প্রতিশ্রুতি না করি। আপনি আরও স্বীকার
                করেছেন এবং সম্মত হন যে ই-পেরিশেবা কর্মচারী এবং ঠিকাদাররা
                ই-প্যারিশেবা গ্রাহক / বণিকও হতে পারে এবং তারা আপনার সাথে
                প্রতিযোগিতা করতে পারে, যদিও তারা এটি করতে আপনার গোপনীয় তথ্য
                ব্যবহার না করে।
              </li>
              <li>
                <i class="flaticon-checked"></i> অ্যাকাউন্টের মালিকানা সম্পর্কিত
                কোনও বিরোধের ক্ষেত্রে আমরা অ্যাকাউন্টের মালিকানা নির্ধারণ বা
                নিশ্চিত করার জন্য ডকুমেন্টেশনের অনুরোধ করার অধিকার সংরক্ষণ করি।
                ডকুমেন্টেশনে আপনার ব্যবসায়িক লাইসেন্সের একটি স্ক্যানকৃত
                অনুলিপি, সরকার জারি করা ফটো আইডি, ফাইলের ক্রেডিট কার্ডের শেষ
                চারটি সংখ্যা, কোনও সত্তার কর্মচারী হিসাবে আপনার অবস্থান ইত্যাদি
                অন্তর্ভুক্ত থাকতে পারে তবে সীমাবদ্ধ নয় ডকুমেন্ট।
              </li>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা আমাদের একমাত্র রায়
                অনুসারে সঠিক অ্যাকাউন্টের মালিকানা নির্ধারণের অধিকার ধরে রাখে
                এবং সঠিক অ্যাকাউন্টের মালিককে কোনও অ্যাকাউন্ট স্থানান্তর করতে
                পারে। আমরা যদি আমাদের অন্যান্য অধিকার ও প্রতিকারের জন্য
                কুসংস্কার ছাড়াই উপযুক্ত স্টোরের মালিককে যুক্তিসঙ্গতভাবে
                নির্ধারণ করতে না পারি, তবে বিতর্ককারী পক্ষগুলির মধ্যে সমাধান
                নির্ধারিত না হওয়া পর্যন্ত ই-প্যারিশেভা অস্থায়ীভাবে কোনও
                অ্যাকাউন্ট অক্ষম করার অধিকার সংরক্ষণ করে।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i>আমরা যে কোনও সময় পরিষেবাটি
                সংশোধন, বাতিল বা প্রত্যাখ্যান করতে পারি।
              </li>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা অ্যাকাউন্টের উপর
                মালিকানার বিবাদের ঘটনা ঘটলে, আমরা আমাদের দ্বারা নির্ধারিত হিসাবে
                অ্যাকাউন্টটি হিমশীতল করে বা সঠিক মালিকের কাছে স্থানান্তর করতে
                পারি।
              </li>
            </ul>
            <h3>5. গোপনীয়তা</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>"গোপনীয় তথ্য" কোনও দলের
                ব্যবসায়ের সাথে সম্পর্কিত এবং সুনির্দিষ্ট ব্যবসায়ের তথ্য,
                প্রযুক্তিগত প্রক্রিয়া এবং সূত্র, সফ্টওয়্যার, গ্রাহক
                তালিকাগুলি, সম্ভাব্য গ্রাহক তালিকাগুলির নাম, অন্তর্ভুক্ত থাকবে
                এমন কোনও তথ্যের অন্তর্ভুক্ত থাকবে তবে সীমাবদ্ধ থাকবে না পার্টির
                , গ্রাহকগণ এবং সম্ভাব্য গ্রাহকগণ, পণ্যের নকশা, বিক্রয়, ব্যয়
                (যে কোনও প্রাসঙ্গিক প্রসেসিং ফি সহ), মূল্য তালিকা এবং অন্যান্য
                অপ্রকাশিত আর্থিক তথ্য, ব্যবসায়িক পরিকল্পনা এবং বিপণনের ডেটা এবং
                অন্য কোনও গোপনীয় এবং মালিকানা সম্পর্কিত তথ্য, ঠিকানা এবং
                অন্যান্য তথ্য অথবা গোপনীয় বা মালিকানা হিসাবে চিহ্নিত নয়
                ই-পরিষেবা গোপনীয় তথ্য আপনি আমাদের সম্পর্কিত পরিষেবাগুলি, বা
                পরিষেবাদি সম্পর্কিত সমস্ত তথ্য অন্তর্ভুক্ত, যা আমাদের সুরক্ষা
                প্রোগ্রাম এবং অনুশীলন সম্পর্কিত তথ্য সহ সাধারণ মানুষের জ্ঞাত
                নয়।
              </li>
              <li>
                <i class="flaticon-checked"></i> প্রতিটি পক্ষই পরিষেবার শর্তাদির
                অধীনে এবং এই শর্তাদিগুলিতে অন্য কোনও বাধ্যবাধকতার সাথে
                সঙ্গতিপূর্ণভাবে তার দায়িত্ব পালনের জন্য কেবলমাত্র অন্য পক্ষের
                গোপনীয় তথ্যই ব্যবহার করতে সম্মত হয়। প্রতিটি পক্ষ সম্মত হয় যে
                এটি তার নিজস্ব মালিকানা সম্পর্কিত তথ্য রক্ষা করতে, যেমন কোনও
                গোপনীয় তথ্যের সদৃশতা, প্রকাশ বা ব্যবহার রোধ করার জন্য
                গ্রহণযোগ্য পদক্ষেপগুলির কমপক্ষে যথেষ্ট পরিমাণে সমান হবে। গোপনীয়
                তথ্যে কোনও তথ্য যা গ্রাহক পক্ষ প্রমাণ করতে পারে তা অন্তর্ভুক্ত
                করবে না: (ক) ইতিমধ্যে জনসাধারণের ডোমেইনে ছিল, বা এই জাতীয় তথ্য
                প্রকাশের সময় গ্রহণকারী পক্ষের দ্বারা বা এর অধীনে ইতিমধ্যে জানা
                ছিল; (খ) অন্য পক্ষের গোপনীয় তথ্যের ব্যবহার বা রেফারেন্স ছাড়াই
                এবং এই পরিষেবার শর্তাদির কোনও বিধান লঙ্ঘন না করে স্বতন্ত্রভাবে
                গ্রহণকারী পক্ষের দ্বারা বিকশিত; বা (সি) এর পরে পরিষেবার শর্তাদির
                কোনও বিধান লঙ্ঘন না করে প্রকাশকারী পক্ষ ব্যতীত অন্য কোনও উত্স
                থেকে প্রাপ্ত পক্ষ দ্বারা যথাযথভাবে প্রাপ্ত হয়।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i>আপনি এবং ই-পরিষেবা একমত উভয়ই এই
                পরিষেবার শর্তাদির দায়বদ্ধতার জন্য অন্যের গোপনীয় তথ্য ব্যবহার
                করতে পারেন। গোপনীয় তথ্য অবশ্যই সুরক্ষিত এবং সম্মানিত করা উচিত।
              </li>
            </ul>
            <h3 class="mt-4">6. দায়বদ্ধতার সীমাবদ্ধতা</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>আপনি স্পষ্টভাবে বুঝতে এবং সম্মত
                হন যে, প্রযোজ্য আইন দ্বারা অনুমোদিত সীমা পর্যন্ত,ই-পরিষেবা লাভের
                ক্ষতির জন্য ক্ষতির সাথেও সীমিত নয় তবে প্রত্যক্ষ, পরোক্ষ,
                ঘটনামূলক, বিশেষ, ফলস্বরূপ বা অনুকরণীয় ক্ষতির জন্য দায়বদ্ধ
                থাকবে না , ব্যবহার, ডেটা বা পরিষেবা ব্যবহারে অক্ষমতা বা ফলস্বরূপ
                অন্যান্য অদম্য লোকসান।
              </li>
              <li>
                <i class="flaticon-checked"></i>প্রযোজ্য আইন দ্বারা অনুমোদিত
                সীমা পর্যন্ত, কোনও ঘটনায় ই-পরিষেবা বা আমাদের সরবরাহকারীরা হ'ল
                লাভ বা কোনও বিশেষ, ঘটনাবলী বা ফলস্বরূপ ক্ষতির জন্য দায়বদ্ধ হতে
                পারবেন না যা আমাদের সাইট, আমাদের পরিষেবাগুলি বা এই পরিষেবার
                শর্তাদি (তবে অবহেলা সহ উত্থাপিত)। আপনি আমাদের তৃতীয় পক্ষের
                দ্বারা প্রদত্ত যুক্তিসঙ্গত অ্যাটর্নিদের ফি সহ যে কোনও দাবি বা
                দাবি নিখুঁতভাবে আমাদের পিতা-মাতা, সহায়ক, সহযোগী, ই-পরিষেবা
                অংশীদার, কর্মকর্তা, পরিচালক, এজেন্ট, কর্মচারী এবং সরবরাহকারীদের
                ক্ষতিপূরণ এবং হোল্ড করতে সম্মত হন ( এই পরিষেবার শর্তাদি বা আপনার
                কোনও আইন লঙ্ঘন বা তৃতীয় পক্ষের অধিকারগুলির লঙ্ঘনের কারণে বা
                উদ্ভূত হয়েছিল।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনার পরিষেবাগুলির ব্যবহার আপনার
                একমাত্র ঝুঁকিতে রয়েছে। পরিষেবাগুলি কোনও ওয়্যারেন্টি বা শর্ত,
                এক্সপ্রেস, ইমপ্লাইড বা বিধিবদ্ধ ছাড়াই "যেমন রয়েছে" এবং "উপলব্ধ
                হিসাবে" ভিত্তিতে সরবরাহ করা হয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা কোনও গ্যারান্টি দেয়
                না যে পরিষেবাগুলি নিরবচ্ছিন্ন, সময়োপযোগী, সুরক্ষিত বা
                ত্রুটিমুক্ত থাকবে।
              </li>
              <li>
                <i class="flaticon-checked"></i>
                ই-পরিষেবা এই নিশ্চয়তা দেয় না যে পরিষেবাগুলির ব্যবহার থেকে
                প্রাপ্ত ফলাফলগুলি নির্ভুল বা নির্ভরযোগ্য হবে।
              </li>
              <li>
                <i class="flaticon-checked"></i> ই-পরিষেবা পরিষেবাদি ব্যবহার
                সম্পর্কিত আপনার কোনও ট্যাক্স দায় বা দায়বদ্ধতার জন্য দায়বদ্ধ
                নয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা কোন গ্যারান্টি দেয় না
                যে পরিষেবাগুলির মাধ্যমে আপনি কেনা বা প্রাপ্ত কোনও পণ্য, পরিষেবা,
                তথ্য বা অন্যান্য উপকরণের গুণমান আপনার প্রত্যাশা পূরণ করবে, বা
                পরিষেবাগুলিতে যে কোনও ত্রুটি সংশোধন করা হবে।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i>যদি আপনি আইন ভঙ্গ করেন, এই
                চুক্তি লঙ্ঘন করেন বা তৃতীয় পক্ষের অধিকারের বিরুদ্ধে যান তবে
                বিশেষত আপনার বিরুদ্ধে মামলা করা হলে দায়বদ্ধ।
              </li>
              <li>
                <i class="flaticon-checked"></i>পরিষেবা "যেমনটি" তাই এতে ত্রুটি
                বা বাধা থাকতে পারে এবং আমরা কোনও ওয়ারেন্টি সরবরাহ করি না এবং
                আমাদের দায়বদ্ধতা সীমাবদ্ধ।
              </li>
            </ul>

            <h3 class="mt-4">8. বৌদ্ধিক সম্পত্তি এবং গ্রাহক সামগ্রী</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>আপনি ই-পরিষেবা পরিষেবাতে যে
                সামগ্রী সরবরাহ করেন সেগুলির উপরে আমরা কোনও বৌদ্ধিক সম্পত্তি
                অধিকার দাবি করি না। আপনি আপনার ই-পরিষেবা স্টোরে আপলোড করেছেন এমন
                সমস্ত উপাদান আপনারই রয়েছে। আপনি আপনার অ্যাকাউন্ট মুছে ফেলার
                মাধ্যমে যে কোনও সময় আপনার ই-পরিষেবা স্টোরটি সরাতে পারবেন।
              </li>
              <li>
                <i class="flaticon-checked"></i>সামগ্রী আপলোড করে আপনি সম্মত হন:
                (ক) অন্যান্য ইন্টারনেট ব্যবহারকারীদের আপনার স্টোরটিতে প্রকাশ্যে
                পোস্ট করা সামগ্রীগুলি দেখার অনুমতি দেওয়ার জন্য; (খ) ইপ্রিশেভা
                সংরক্ষণের অনুমতি দেওয়ার জন্য, এবং সামগ্রীর ক্ষেত্রে আপনি
                প্রকাশ্যে পোস্ট করেন, আপনার সামগ্রীগুলি প্রদর্শন করুন এবং
                ব্যবহার করুন; এবং (গ) যে ই-পরিষেবা, যে কোনও সময়ে, তার
                পরিষেবাদিতে জমা দেওয়া সমস্ত সামগ্রী পর্যালোচনা এবং মুছে ফেলতে
                পারে, যদিও ই-পরিষেবা এটি করার জন্য বাধ্য নয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>আপনি স্টোরটিতে আপলোড করেন এমন
                সমস্ত উপাদানের উপর আপনি মালিকানা বজায় রাখবেন; তবে আপনার
                স্টোরটিকে সর্বজনীন করে, আপনি অন্যকে আপনার স্টোরটিতে প্রকাশ্যে
                পোস্ট করা সামগ্রীগুলি দেখার অনুমতি দিতে সম্মত হন। আপনি যে কোনও
                প্রযোজ্য আইন বা বিধিবিধানের সাথে সামগ্রীগুলির সম্মতির জন্য
                দায়বদ্ধ।
              </li>
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা কাছে পরিষেবাটির
                প্রচারের জন্য আপনার স্টোরের সাথে যুক্ত নাম, ট্রেডমার্ক, পরিষেবা
                চিহ্ন এবং লোগো ব্যবহার করার জন্য একচেটিয়া অধিকার এবং লাইসেন্স
                থাকবে।
              </li>
              <li>
                <h3>যার অর্থ</h3>
                <i class="flaticon-checked"></i>আপনি যে কিছু আপলোড করবেন তা
                আপনারই রয়ে গেছে এবং এটি আপনার দায়িত্ব।
              </li>
            </ul>
            <h3 class="mt-4">9.বাতিলকরণ এবং সমাপ্তি</h3>

            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> আপনি আপনার অ্যাকাউন্ট বাতিল
                করতে পারেন এবং যেকোন সময় পরিষেবার শর্তাদ। ই-পরিষেবা সাপোর্টের
                সাথে যোগাযোগ করে এবং তারপরে ই-পরিষেবা প্রতিক্রিয়াতে আপনাকে
                নির্দেশিত নির্দিষ্ট নির্দেশাবলীর অনুসরণ করে শেষ করতে পারেন।
              </li>
              <li>2. কোনও কারণে পক্ষ দ্বারা পরিষেবাগুলি সমাপ্ত করার পরে:</li>
              <li>
                1. ই-পরিষেবা আপনাকে পরিষেবাগুলি সরবরাহ করা বন্ধ করবে এবং আপনি আর
                আপনার অ্যাকাউন্টে অ্যাক্সেস করতে পারবেন না;
              </li>
              <li>
                2. অন্যথায় পরিষেবার শর্তাদিতে প্রদত্ত না হলে আপনি কোনও ফি ফেরত
                পাওয়ার অধিকারী হবেন না।
              </li>
              <li>
                3. এই ধরনের সমাপ্তির কার্যকর তারিখের মাধ্যমে আপনার পরিষেবাগুলির
                ব্যবহারের জন্য ই-পরিষেবা অন্য কোনও বকেয়া অবিলম্বে তৎক্ষণাৎ
                যথাযথ এবং সম্পূর্ণ পরিশোধযোগ্য হয়ে যাবে; এবং
              </li>
              <li>4. আপনার স্টোর ওয়েবসাইটটি অফলাইনে নেওয়া হবে।</li>
              <li>
                <i class="flaticon-checked"></i> যদি পরিষেবাটি সমাপ্ত হওয়ার
                তারিখে আপনার দ্বারা বকেয়া কোনও ফি থাকে তবে আপনি ইমেলের মাধ্যমে
                একটি চূড়ান্ত চালান পাবেন। একবার চালানের পুরো অর্থ প্রদান করা
                হয়ে গেলে, আপনাকে আর চার্জ করা হবে না।
              </li>
              <li>
                <i class="flaticon-checked"></i> আমরা যে কোনও সময়ে বিজ্ঞপ্তি
                ছাড়াই (যেকোনো সময় প্রযোজ্য আইনের দ্বারা প্রয়োজনীয় না হলে)
                কোনও কারণে বিজ্ঞপ্তি ছাড়াই ই-পরিষেবা , পরিষেবার শর্তাদি এবং /
                অথবা আপনার অ্যাকাউন্ট সংশোধন বা সমাপ্ত করার অধিকার সংরক্ষণ করি।
                পরিষেবার শর্তাদির সমাপ্তি সমাপ্তির তারিখের আগে উত্থাপিত কোনও
                অধিকার বা বাধ্যবাধকতাগুলির প্রতি পূর্বসংশ্লিষ্ট নয়।
              </li>
              <li>
                <i class="flaticon-checked"></i>জালিয়াতি: অন্য কোনও প্রতিকার
                সীমাবদ্ধ না করেই, ই-পরিষেবা আপনাকে (দোষী সাব্যস্ততা, নিষ্পত্তি,
                বীমা বা এসক্রো তদন্ত দ্বারা, বা অন্যথায়) পরিষেবাগুলি ব্যবহারের
                সাথে জালিয়াতিমূলক কার্যকলাপে জড়িত বলে সন্দেহ করলে আপনার
                অ্যাকাউন্ট স্থগিত বা বন্ধ করে দেয়।
              </li>
              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i> একটি সমাপ্তির যোগাযোগের সূচনা
                করার জন্য সহায়তা। ই-পরিষেবা আপনার অ্যাকাউন্টের সমাপ্তি
                প্রক্রিয়া সম্পর্কিত নির্দিষ্ট তথ্যের সাথে প্রতিক্রিয়া জানায়।
                একবার সমাপ্তির বিষয়টি নিশ্চিত হয়ে গেলে, ই-পরিষেবা মাধ্যমে কেনা
                ডোমেনগুলি স্বয়ংক্রিয়ভাবে পুনর্নবীকরণ হবে না। আপনি যদি আপনার
                বিলিং চক্রের মাঝখানে বাতিল করেন, আপনার কাছে একটি শেষ ইমেল চালান
                হবে।
              </li>
              <li>
                <i class="flaticon-checked"></i> আমরা যে কোনও সময় আপনার
                অ্যাকাউন্টটি পরিবর্তন বা শেষ করতে পারি। কোনও জালিয়াতি এবং আমরা
                আপনার অ্যাকাউন্ট স্থগিত বা বন্ধ করব।
              </li>
            </ul>
            <h3 class="mt-4">10. পরিষেবা এবং দামগুলিতে পরিবর্তন</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> পরিষেবাগুলি ব্যবহারের জন্য
                দামগুলি ই-প্যারিশেবা থেকে 30 দিনের নোটিশের পরে পরিবর্তনযোগ্য।
              </li>

              <li>
                <i class="flaticon-checked"></i> ই-পরিষেবা যে কোনও সময় এবং
                সময়ে সময়ে, পরিষেবাগুলি (বা এর কোনও অংশ) বিজ্ঞপ্তি ছাড়াই বা
                ছাড়াই (পরিবর্তনযোগ্য আইন দ্বারা অন্যথায় প্রয়োজনীয় না হলে)
                সংশোধন বা বন্ধ করার অধিকার সংরক্ষণ করে।
              </li>
              <li>
                <i class="flaticon-checked"></i> ই-পরিষেবা আপনার বা তৃতীয়
                পক্ষের কাছে কোনও পরিবর্তন, মূল্য পরিবর্তন, স্থগিতকরণ বা
                পরিষেবাটি বন্ধ করার জন্য দায়বদ্ধ থাকবে না।
              </li>

              <h3>যার অর্থ</h3>
              <li>
                <i class="flaticon-checked"></i> আমরা দায়বদ্ধতা ছাড়াই যে কোনও
                সময় পরিষেবাটি পরিবর্তন বা বন্ধ করতে পারি।.
              </li>
            </ul>
            <h3 class="mt-4">11. গোপনীয়তা এবং ডেটা সুরক্ষা</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>ই-পরিষেবা আপনার ব্যক্তিগত তথ্য
                এবং আপনার গ্রাহকদের ব্যক্তিগত তথ্য গোপনীয়তা রক্ষা করতে দৃঢ়
                প্রতিজ্ঞাবদ্ধ। পরিষেবাটি ব্যবহার করে আপনি স্বীকৃতি ও সম্মতি
                দিচ্ছেন যে এই ব্যক্তিগত তথ্যের ই-পরিষেবা সংগ্রহ, ব্যবহার এবং
                প্রকাশ আমাদের গোপনীয়তা নীতি দ্বারা পরিচালিত হয় ।
              </li>
            </ul>
          </div>
        </div>
        <div class="row" v-if="selected === 'TELAGU'">
          <div class="col-sm-12">
            <h2 class="text-center mb-5">eParisheva సేవా నిబంధనలు</h2>
            <ul class="eb-useing-gumtree">
              <li>Lచివరిగా నవీకరించబడింది: ఏప్రిల్ 15, 2021</li>
              <li>
                EParisheva ఖాతా కోసం సైన్ అప్ చేయడం ద్వారా (సెక్షన్ 1 లో
                నిర్వచించినట్లు) లేదా ఏదైనా eParisheva సేవలను ఉపయోగించడం ద్వారా
                (క్రింద నిర్వచించినట్లు), మీరు ఈ క్రింది నిబంధనలు మరియు షరతులకు
                (“సేవా నిబంధనలు”) కట్టుబడి ఉండాలని అంగీకరిస్తున్నారు. ఈ సేవా
                నిబంధనలలో ఉపయోగించినట్లుగా, “మేము”, “మాకు” మరియు “ఇపరిషెవా” అంటే
                వర్తించే ఇపారిషేవా కాంట్రాక్టింగ్ పార్టీ (క్రింద ఉన్న సెక్షన్ 4
                లో నిర్వచించినట్లు). సేవా నిబంధనల క్రింద ఇపరిషెవా అందించే
                సేవల్లో ఆన్‌లైన్‌లో (“ఆన్‌లైన్ సేవలు”) కొనుగోలుదారులకు వస్తువులు
                మరియు సేవలను విక్రయించడంలో మీకు సహాయపడటానికి వివిధ ఉత్పత్తులు
                మరియు సేవలు ఉన్నాయి, వ్యక్తిగతంగా (“POS సేవలు ”), లేదా రెండూ.
                EParisheva అందించే అటువంటి సేవలను ఈ సేవా నిబంధనలలో “సేవలు” గా
                సూచిస్తారు.ప్రస్తుత సేవలకు జోడించబడిన ఏదైనా క్రొత్త లక్షణాలు
                లేదా సాధనాలు కూడా సేవా నిబంధనలకు లోబడి ఉంటాయి. eParisheva
                వెబ్‌సైట్‌లో నవీకరణలు మరియు మార్పులను పోస్ట్ చేయడం ద్వారా సేవా
                నిబంధనలను నవీకరించడానికి మరియు మార్చడానికి eParisheva హక్కు
                ఉంది.మిమ్మల్ని ప్రభావితం చేసే ఏవైనా నవీకరణలు లేదా మార్పుల కోసం
                ఎప్పటికప్పుడు సేవా నిబంధనలను తనిఖీ చేయాలని మీకు సలహా ఇవ్వబడింది
                మరియు మీరు అలాంటి సవరణలను అంగీకరించకపోతే, మీరు సేవలను ఉపయోగించడం
                మానేయాలి. మీరు eParisheva ఖాతా కోసం సైన్ అప్ చేయడానికి లేదా
                ఏదైనా eParisheva సేవను ఉపయోగించటానికి ముందు మీరు ఈ సేవా
                నిబంధనలలో ఉన్న లేదా స్పష్టంగా సూచించబడిన అన్ని నిబంధనలు మరియు
                షరతులను తప్పక చదవాలి, అంగీకరించాలి మరియు అంగీకరించాలి. రోజువారీ
                భాషా సారాంశాలు సౌలభ్యం కోసం మాత్రమే అందించబడతాయి మరియు ప్రతి
                విభాగం కింద బోల్డ్‌లో కనిపిస్తాయి, అయితే ఈ సారాంశాలు చట్టబద్ధంగా
                కట్టుబడి ఉండవు. మీ చట్టపరమైన అవసరాల పూర్తి చిత్రం కోసం దయచేసి ఈ
                సేవా నిబంధనలలో సూచించబడిన ఏదైనా పత్రంతో సహా సేవా నిబంధనలను
                చదవండి. EParisheva లేదా ఏదైనా eParisheva సేవలను ఉపయోగించడం
                ద్వారా, మీరు ఈ నిబంధనలను అంగీకరిస్తున్నారు. నవీకరణల కోసం
                అప్పుడప్పుడు తిరిగి తనిఖీ చేయండి.
              </li>
              <h3>1.ఖాతా నిబంధనలు</h3>

              <li>
                <i class="flaticon-checked"></i> సేవలను యాక్సెస్ చేయడానికి మరియు
                ఉపయోగించడానికి, మీరు మీ పూర్తి చట్టపరమైన పేరు, ప్రస్తుత
                చిరునామా, ఫోన్ నంబర్, చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామా మరియు
                అవసరమైన విధంగా సూచించిన ఇతర సమాచారాన్ని అందించడం ద్వారా మీరు
                eParisheva ఖాతా (“ఖాతా”) కోసం నమోదు చేసుకోవాలి. eParisheva ఒక
                ఖాతా కోసం మీ దరఖాస్తును తిరస్కరించవచ్చు లేదా ఇప్పటికే ఉన్న
                ఖాతాను ఏ కారణం చేతనైనా మా స్వంత అభీష్టానుసారం రద్దు చేయవచ్చు.
              </li>
              <li>
                <i class="flaticon-checked"></i> మీరు వీటిలో పెద్దవారై ఉండాలి:
                (i) 18 సంవత్సరాలు, లేదా (ii) మీరు నివసించే అధికార పరిధిలో కనీసం
                మెజారిటీ వయస్సు ఉండాలి మరియు మీరు ఖాతా తెరవడానికి సేవలను
                ఉపయోగిస్తున్నారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>వ్యాపార కార్యకలాపాలను కొనసాగించే
                ప్రయోజనాల కోసం మరియు వ్యక్తిగత, గృహ లేదా కుటుంబ ప్రయోజనాల కోసం
                కాకుండా ఇపారిషేవా అందించిన ఏదైనా సేవలను మీరు స్వీకరిస్తున్నారని
                మీరు ధృవీకరిస్తున్నారు.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఒక ఖాతాను తెరిచినప్పుడు లేదా
                మీతో కమ్యూనికేట్ చేయడానికి ప్రాథమిక పద్ధతిగా ఎప్పటికప్పుడు మీరు
                అప్‌డేట్ చేసినట్లుగా మీరు అందించే ఇమెయిల్ చిరునామా / ఫోన్
                నంబర్‌ను ఇపరిషెవా ఉపయోగిస్తుందని మీరు గుర్తించారు. మీరు
                eParisheva కు అందించే ప్రాధమిక ఖాతా ఇమెయిల్ చిరునామాను మీరు
                తప్పక పర్యవేక్షించాలి మరియు మీ ప్రాధమిక ఖాతా ఇమెయిల్ చిరునామా
                సందేశాలను పంపడం మరియు స్వీకరించడం రెండింటినీ కలిగి ఉండాలి. మీ
                ప్రాధమిక ఖాతా ఇమెయిల్ చిరునామా నుండి వచ్చినట్లయితే మాత్రమే
                eParisheva తో మీ ఇమెయిల్ కమ్యూనికేషన్లు ప్రామాణీకరించబడతాయి
              </li>
              <li>
                <i class="flaticon-checked"></i> మీ పాస్‌వర్డ్‌ను సురక్షితంగా
                ఉంచాల్సిన బాధ్యత మీపై ఉంది. మీ ఖాతా మరియు పాస్‌వర్డ్ యొక్క
                భద్రతను కాపాడుకోవడంలో మీ వైఫల్యం నుండి నష్టం లేదా నష్టానికి
                eParisheva బాధ్యత వహించదు.
              </li>
              <li>
                <i class="flaticon-checked"></i> మీ eParisheva స్టోర్ యొక్క
                సృష్టి మరియు కార్యకలాపాలకు మీరు బాధ్యత వహిస్తున్నారని మీరు
                గుర్తించారు.
              </li>
              <li>
                <i class="flaticon-checked"></i> మార్కెట్ కాదు. మీ eParisheva
                స్టోర్ ద్వారా విక్రయించే ఏదైనా ఒప్పందం మీకు మరియు కొనుగోలుదారుకు
                మధ్య నేరుగా ఉంటుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఫోటోలు, చిత్రాలు, వీడియోలు,
                గ్రాఫిక్స్, వ్రాతపూర్వక కంటెంట్, ఆడియో ఫైల్స్, కోడ్, సమాచారం
                లేదా అప్‌లోడ్ చేసిన, సేకరించిన, ఉత్పత్తి చేసిన, నిల్వ చేసిన,
                ప్రదర్శించిన, పంపిణీ చేసిన, ప్రసారం చేసిన లేదా ప్రదర్శించిన లేదా
                వంటి అన్ని కార్యాచరణ మరియు కంటెంట్‌కు మీరు బాధ్యత వహిస్తారు. మీ
                ఖాతా (“మెటీరియల్స్”).
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవా నిబంధనలలో ఏదైనా పదాన్ని
                ఉల్లంఘించడం లేదా ఉల్లంఘించడం, ఇపరిషెవా యొక్క స్వంత అభీష్టానుసారం
                నిర్ణయించినట్లు మీ సేవలను వెంటనే రద్దు చేయవచ్చు.
              </li>
              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i>మేము మిమ్మల్ని చేరుకోవాల్సిన
                అవసరం ఉంటే, మేము మీకు ఇమెయిల్ పంపుతాము.
              </li>
            </ul>
            <h3>2.ఖాతా సక్రియం</h3>
            <h5 style="font-weight: bold">2.1 స్టోర్ యజమాని</h5>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>ఖాతాను తెరవడం ద్వారా సేవ కోసం
                సైన్ అప్ చేసే వ్యక్తి మా సేవా నిబంధనల ప్రయోజనాల కోసం కాంట్రాక్ట్
                పార్టీ (“స్టోర్ యజమాని”) మరియు స్టోర్ యజమానికి మేము అందించే
                ఏదైనా సంబంధిత ఖాతాను ఉపయోగించడానికి అధికారం ఉన్న వ్యక్తి
                అవుతారు. సేవకు సంబంధించి. స్టోర్ వెబ్‌సైట్‌లో స్టోర్ యజమాని పేరు
                (స్టోర్ కలిగి ఉన్న సంస్థ యొక్క చట్టపరమైన పేరుతో సహా) స్పష్టంగా
                కనిపించేలా చూడాల్సిన బాధ్యత మీపై ఉంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీరు మీ యజమాని తరపున సేవలకు సైన్
                అప్ చేస్తుంటే, మీ యజమాని స్టోర్ యజమాని. మీరు మీ యజమాని తరపున
                సేవలకు సైన్ అప్ చేస్తుంటే, మీరు మీ యజమాని జారీ చేసిన ఇమెయిల్
                చిరునామాను తప్పక ఉపయోగించాలి మరియు మీ యజమానిని మా సేవా నిబంధనలకు
                బంధించే అధికారం మీకు ఉందని మీరు ప్రాతినిధ్యం వహిస్తారు మరియు
                హామీ ఇస్తారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీ eParisheva స్టోర్ ఒక స్టోర్
                యజమానితో మాత్రమే అనుబంధించబడుతుంది. స్టోర్ యజమాని బహుళ
                eParisheva స్టోర్లను కలిగి ఉండవచ్చు. “స్టోర్” అంటే ఖాతాతో
                అనుబంధించబడిన ఆన్‌లైన్ స్టోర్ లేదా భౌతిక రిటైల్ స్థానం (లు).
              </li>
              <h5 style="font-weight: bold" class="mt-3">
                2.2 సిబ్బంది ఖాతాలు
              </h5>
              <li>
                <i class="flaticon-checked"></i>మీ eParisheva సభ్యత్వ ప్రణాళిక
                ఆధారంగా, మీరు ఒకటి లేదా అంతకంటే ఎక్కువ సిబ్బంది ఖాతాలను (“స్టాఫ్
                అకౌంట్స్”) సృష్టించవచ్చు. స్టాఫ్ అకౌంట్స్‌తో, స్టోర్ యజమాని
                అనుమతులను సెట్ చేయవచ్చు మరియు స్టాఫ్ అకౌంట్స్ ద్వారా నిర్దిష్ట
                వ్యాపార సమాచారానికి ప్రాప్యత స్థాయిని నిర్ణయించేటప్పుడు ఇతర
                వ్యక్తులను వారి ఖాతాలో పని చేయగలుగుతారు (ఉదాహరణకు, మీరు
                రిపోర్ట్స్ పేజీలోని అమ్మకాల సమాచారానికి స్టాఫ్ ఖాతా ప్రాప్యతను
                పరిమితం చేయవచ్చు లేదా నిరోధించవచ్చు సాధారణ స్టోర్ సెట్టింగులను
                మార్చకుండా సిబ్బంది ఖాతాలు).
              </li>
              <li>
                <i class="flaticon-checked"></i>ఈ సేవా నిబంధనల ప్రకారం బాధ్యతల
                పనితీరులో స్టాఫ్ అకౌంట్ల వాడకం వల్ల ఉత్పన్నమయ్యే చర్యలు, లోపాలు
                మరియు డిఫాల్ట్‌లకు స్టోర్ యజమాని బాధ్యత మరియు బాధ్యత వహిస్తాడు,
                అవి స్టోర్ యజమాని యొక్క స్వంత చర్యలు, లోపాలు లేదా డిఫాల్ట్‌లు.
              </li>
              <li>
                <i class="flaticon-checked"></i>స్టోర్ యజమాని మరియు స్టాఫ్
                అకౌంట్స్ క్రింద ఉన్న వినియోగదారులను ప్రతి ఒక్కటి “ఇపారిషేవా
                యూజర్” గా సూచిస్తారు.
              </li>

              <h3 class="mt-4">3. సాధారణ పరిస్థితులు</h3>
              <li class="text-justify">
                గోప్యతా విధానంతో సహా ఈ సేవా నిబంధనలలోని అన్ని నిబంధనలు మరియు
                షరతులను మీరు తప్పక చదవాలి, అంగీకరించాలి మరియు అంగీకరించాలి.
                అదనంగా, మీరు అటువంటి ఉత్పత్తులను విక్రయిస్తే COVID-19 సంబంధిత
                ఉత్పత్తుల అమ్మకం కోసం ఎంగేజ్‌మెంట్ నియమాలను మీరు
                అంగీకరిస్తున్నారు మరియు అంగీకరిస్తారు.
              </li>
            </ul>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>Tసేవలకు సంబంధించి సాంకేతిక
                మద్దతు ఇపారిషేవా వినియోగదారులకు మాత్రమే అందించబడుతుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>సంబంధిత సవరించిన మరియు పున
                ప్రారంభించిన సేవా నిబంధనలను పోస్ట్ చేయడం ద్వారా ఏ సమయంలోనైనా ఈ
                సేవా నిబంధనలను ఇపరిషెవా సవరించవచ్చని మీరు గుర్తించి,
                అంగీకరిస్తున్నారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీరు చట్టవిరుద్ధమైన లేదా
                అనధికారమైన ప్రయోజనం కోసం eParisheva సేవలను ఉపయోగించకపోవచ్చు లేదా
                సేవ యొక్క ఉపయోగంలో, మీ అధికార పరిధిలోని ఏదైనా చట్టాలను (కాపీరైట్
                చట్టాలతో సహా, పరిమితం కాకుండా), మీ కస్టమర్ యొక్క అధికార పరిధిలో
                మీకు వర్తించే చట్టాలను ఉల్లంఘించకూడదు..
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవ యొక్క ఏదైనా భాగాన్ని
                పునరుత్పత్తి, నకిలీ, కాపీ, అమ్మకం, పున ell విక్రయం లేదా దోపిడీ
                చేయకూడదని మీరు అంగీకరిస్తున్నారు, సేవల ఉపయోగం లేదా సేవలకు
                ప్రాప్యత ఇపరిషేవా యొక్క ఎక్స్ప్రెస్ వ్రాతపూర్వక అనుమతి లేకుండా.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీరు శోధన ఇంజిన్ లేదా ప్రతి
                క్లిక్ కీలకపదాలకు (గూగుల్ ప్రకటనలు వంటివి) లేదా ఇపారిషేవా లేదా
                ఇపరిషెవా ట్రేడ్‌మార్క్‌లు మరియు / లేదా వైవిధ్యాలు మరియు
                అక్షరదోషాలను ఉపయోగించే డొమైన్ పేర్లను కొనుగోలు చేయకూడదు.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవా నిబంధనల గురించి ప్రశ్నలు
                eParisheva మద్దతుకు పంపాలి.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీ మెటీరియల్స్ (క్రెడిట్ కార్డ్
                సమాచారంతో సహా కాదు), గుప్తీకరించబడకుండా బదిలీ చేయబడవచ్చని మరియు
                (ఎ) వివిధ నెట్‌వర్క్‌లలో ప్రసారాలను కలిగి ఉంటుందని మీరు అర్థం
                చేసుకున్నారు; మరియు (బి) నెట్‌వర్క్‌లు లేదా పరికరాలను కనెక్ట్
                చేసే సాంకేతిక అవసరాలకు అనుగుణంగా మరియు అనుగుణంగా మార్పులు.
                నెట్‌వర్క్‌ల ద్వారా బదిలీ చేసేటప్పుడు క్రెడిట్ కార్డ్ సమాచారం
                ఎల్లప్పుడూ గుప్తీకరించబడుతుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>సంక్షిప్త సందేశ సేవ (SMS) సందేశం
                ద్వారా మీ వినియోగదారులకు కొన్ని కమ్యూనికేషన్లను పంపడానికి సేవలు
                మిమ్మల్ని అనుమతిస్తాయి (ఉదాహరణకు, SMS ద్వారా ఆర్డర్ నిర్ధారణ
                నోటిఫికేషన్లను పంపడం) ("SMS సేవలు"). మీరు ఈ సేవా నిబంధనలకు
                అనుగుణంగా మాత్రమే SMS సేవలను ఉపయోగిస్తారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>EParisheva ద్వారా ప్రసారం
                చేయబడిన లేదా నిల్వ చేయబడిన సమాచారంతో సహా మీ సేవల ఉపయోగం దాని
                గోప్యతా విధానం ద్వారా నిర్వహించబడుతుందని మీరు గుర్తించి,
                అంగీకరిస్తున్నారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవా నిబంధనలు ఇంగ్లీష్ కాకుండా
                ఇతర భాషలలో అందుబాటులో ఉండవచ్చు. ఈ ఆంగ్ల సేవా నిబంధనలు మరియు
                eParisheva యొక్క సేవా నిబంధనల మధ్య ఏదైనా అసమానతలు లేదా విభేదాలు
                మరొక భాషలో లభిస్తే, సేవా నిబంధనల యొక్క ప్రస్తుత ఆంగ్ల సంస్కరణ
                ప్రబలంగా ఉంటుంది. ఈ సేవా నిబంధనల నుండి ఉత్పన్నమయ్యే ఏవైనా
                వివాదాలు ఇపారిషేవా (దాని స్వంత అభీష్టానుసారం వ్యవహరించడం) లేదా
                వర్తించే చట్టం ప్రకారం నిర్ణయించకపోతే ఆంగ్లంలో పరిష్కరించబడతాయి.
              </li>
              <li>
                <i class="flaticon-checked"></i>టాయి. మీకు నోటీసు ఇవ్వకుండా లేదా
                మీ నుండి సమ్మతి లేకుండా ఈ సేవా నిబంధనలను కేటాయించడానికి
                eParisheva అనుమతించబడుతుంది. ఇపరిషెవా యొక్క ముందస్తు వ్రాతపూర్వక
                అనుమతి లేకుండా ఏ మూడవ పార్టీకి సేవా నిబంధనలను లేదా మీ హక్కులు
                లేదా బాధ్యతలను కేటాయించడానికి లేదా బదిలీ చేయడానికి మీకు హక్కు
                లేదు, ఇపరిషెవా యొక్క స్వంత అభీష్టానుసారం ఇవ్వడం లేదా
                నిలిపివేయడం.
              </li>
              <h3>WHICH MEANS</h3>
              <li>
                <i class="flaticon-checked"></i>ఇపరిషేవ సేవ మనకు చెందినది.
                దాన్ని చీల్చడానికి లేదా ఏదైనా చట్టవిరుద్ధమైన లేదా స్కెచి
                ప్రయోజనం కోసం ఉపయోగించడానికి మీకు అనుమతి లేదు. మీరు లేదా మీ
                ఉద్యోగులు లేదా కాంట్రాక్టర్లు eParisheva యొక్క API లను
                ఉపయోగిస్తే, మీరు మా API నిబంధనలకు లోబడి ఉంటారు.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీ మెటీరియల్స్
                గుప్తీకరించబడకుండా బదిలీ చేయబడవచ్చు మరియు మార్చబడవచ్చు, కానీ
                క్రెడిట్ కార్డ్ సమాచారం ఎల్లప్పుడూ గుప్తీకరించబడుతుంది.
              </li>
            </ul>
            <h3 class="my-4">4.eParisheva హక్కులు</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> ఏ సమయంలోనైనా నోటీసు లేకుండా, ఏ
                కారణం చేతనైనా సేవలను సవరించడానికి లేదా ముగించే హక్కు మాకు ఉంది.
                ప్రతి అధికార పరిధిలో అన్ని సేవలు మరియు లక్షణాలు అందుబాటులో లేవు
                మరియు ఏదైనా అధికార పరిధిలో ఏ సేవలు లేదా లక్షణాలను అందుబాటులో
                ఉంచే బాధ్యత మాకు లేదు.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఎప్పుడైనా ఏ కారణం చేతనైనా
                ఎవరికైనా సేవలను తిరస్కరించే హక్కు మాకు ఉంది.
              </li>
              <li>
                <i class="flaticon-checked"></i> స్టోర్ ద్వారా అందించే వస్తువులు
                లేదా సేవలు లేదా స్టోర్‌లోకి అప్‌లోడ్ చేయబడిన లేదా పోస్ట్ చేసిన
                పదార్థాలు మా సేవా నిబంధనలను ఉల్లంఘిస్తాయని మా స్వంత
                అభీష్టానుసారం మేము నిర్ణయిస్తే, మెటీరియల్స్ తొలగించడం మరియు
                ఖాతాలను నిలిపివేయడం లేదా ముగించడం మాకు బాధ్యత కాదు.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఏదైనా eParisheva కస్టమర్,
                eParisheva ఉద్యోగి, సభ్యుడు లేదా అధికారి యొక్క ఏదైనా రకమైన
                (దుర్వినియోగం లేదా ప్రతీకార బెదిరింపులతో సహా) శబ్ద లేదా
                వ్రాతపూర్వక దుర్వినియోగం తక్షణ ఖాతా రద్దుకు దారితీస్తుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i> eParisheva మెటీరియల్‌లను
                ప్రీ-స్క్రీన్ చేయదు మరియు మీ స్టోర్‌తో సహా సేవ నుండి ఏదైనా
                మెటీరియల్‌లను తిరస్కరించడం లేదా తొలగించడం మా స్వంత
                అభీష్టానుసారం.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీ పోటీదారులకు మా సేవలను అందించే
                హక్కు మాకు ఉంది మరియు ఏదైనా నిర్దిష్ట మార్కెట్ విభాగంలో
                ప్రత్యేకత గురించి ఎటువంటి హామీ ఇవ్వలేదు. EParisheva ఉద్యోగులు
                మరియు కాంట్రాక్టర్లు కూడా eParisheva కస్టమర్లు / వ్యాపారులు
                కావచ్చు మరియు వారు మీతో పోటీ పడవచ్చు అని మీరు ఇంకా గుర్తించి,
                అంగీకరిస్తున్నారు, అయినప్పటికీ వారు మీ రహస్య సమాచారాన్ని
                ఉపయోగించకపోవచ్చు.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఖాతా యాజమాన్యానికి సంబంధించి
                వివాదం సంభవించినప్పుడు, ఖాతా యాజమాన్యాన్ని నిర్ణయించడానికి లేదా
                నిర్ధారించడానికి డాక్యుమెంటేషన్‌ను అభ్యర్థించే హక్కు మాకు ఉంది.
                డాక్యుమెంటేషన్‌లో మీ వ్యాపార లైసెన్స్ యొక్క స్కాన్ చేసిన కాపీ,
                ప్రభుత్వం జారీ చేసిన ఫోటో ఐడి, ఫైల్‌లోని క్రెడిట్ కార్డు యొక్క
                చివరి నాలుగు అంకెలు, ఎంటిటీ ఉద్యోగిగా మీ స్థితి మొదలైనవి
                ఉండవచ్చు.
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva మా ఏకైక తీర్పులో,
                సరైన ఖాతా యాజమాన్యాన్ని నిర్ణయించే హక్కును కలిగి ఉంది మరియు ఒక
                ఖాతాను సరైన స్టోర్ యజమానికి బదిలీ చేస్తుంది. మా ఇతర హక్కులు
                మరియు పరిష్కారాలకు పక్షపాతం లేకుండా, సరైన స్టోర్ యజమానిని మేము
                సహేతుకంగా నిర్ణయించలేకపోతే, వివాదాస్పద పార్టీల మధ్య తీర్మానం
                నిర్ణయించబడే వరకు ఖాతాను తాత్కాలికంగా నిలిపివేసే హక్కు
                ఇపరిషెవాకు ఉంది.
              </li>
              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i>మేము ఎప్పుడైనా సేవను
                సవరించవచ్చు, రద్దు చేయవచ్చు లేదా తిరస్కరించవచ్చు.
              </li>
              <li>
                <i class="flaticon-checked"></i>EParisheva ఖాతాపై యాజమాన్య
                వివాదం సంభవించినప్పుడు, మేము నిర్ణయించినట్లుగా, మేము ఖాతాను
                స్తంభింపజేయవచ్చు లేదా సరైన యజమానికి బదిలీ చేయవచ్చు.
              </li>
            </ul>
            <h3>5. గోప్యత</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> 1. “రహస్య సమాచారం” లో నిర్దిష్ట
                వ్యాపార సమాచారం, సాంకేతిక ప్రక్రియలు మరియు సూత్రాలు,
                సాఫ్ట్‌వేర్, కస్టమర్ జాబితాలు, కాబోయే కస్టమర్ జాబితాలు, పేర్లు,
                చిరునామాలతో సహా పార్టీ వ్యాపారంతో సంబంధం ఉన్న మరియు బహిరంగంగా
                తెలియని ఏదైనా మరియు మొత్తం సమాచారం ఉండాలి. మరియు కస్టమర్‌లు
                మరియు కాబోయే కస్టమర్‌లకు సంబంధించిన ఇతర సమాచారం, ఉత్పత్తి
                నమూనాలు, అమ్మకాలు, ఖర్చులు (ఏదైనా సంబంధిత ప్రాసెసింగ్ ఫీజుతో
                సహా), ధర జాబితాలు మరియు ఇతర ప్రచురించని ఆర్థిక సమాచారం, వ్యాపార
                ప్రణాళికలు మరియు మార్కెటింగ్ డేటా మరియు గుర్తించబడకపోయినా ఇతర
                రహస్య మరియు యాజమాన్య సమాచారం. రహస్యంగా లేదా యాజమాన్యంగా.
                eParisheva యొక్క రహస్య సమాచారంలో మా భద్రతా కార్యక్రమం మరియు
                అభ్యాసాలకు సంబంధించిన సమాచారంతో సహా సాధారణ ప్రజలకు తెలియని మాతో
                లేదా సేవలకు సంబంధించి మీరు స్వీకరించే మొత్తం సమాచారం ఉంటుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>ఈ సేవా నిబంధనల ప్రకారం మరియు ఈ
                నిబంధనలలోని ఇతర బాధ్యతలకు అనుగుణంగా ఇతర పార్టీ యొక్క రహస్య
                సమాచారాన్ని ఉపయోగించడానికి ప్రతి పార్టీ అంగీకరిస్తుంది. ప్రతి
                పార్టీ తన స్వంత యాజమాన్య సమాచారాన్ని రక్షించుకోవడానికి, అటువంటి
                రహస్య సమాచారం యొక్క నకిలీ, బహిర్గతం లేదా వాడకాన్ని
                నిరోధించడానికి తీసుకునే చర్యలకు కనీసం సమానమైన అన్ని సహేతుకమైన
                చర్యలను తీసుకుంటుందని అంగీకరిస్తుంది. రహస్య సమాచారం స్వీకరించే
                పార్టీ నిరూపించగల ఏ సమాచారాన్ని కలిగి ఉండకూడదు: (ఎ) అప్పటికే
                పబ్లిక్ డొమైన్‌లో ఉంది, లేదా అటువంటి సమాచారం బహిర్గతం చేసే
                సమయంలో, స్వీకరించే పార్టీ చేత లేదా స్వాధీనం చేసుకుంది; (బి) ఇతర
                పార్టీ యొక్క రహస్య సమాచారాన్ని ఉపయోగించకుండా లేదా సూచించకుండా
                మరియు ఈ సేవా నిబంధనల యొక్క ఏ నిబంధనలను ఉల్లంఘించకుండా స్వీకరించే
                పార్టీ స్వతంత్రంగా అభివృద్ధి చేస్తుంది; లేదా (సి) ఈ సేవా నిబంధనల
                యొక్క ఏ నిబంధనలను ఉల్లంఘించకుండా బహిర్గతం చేసే పార్టీ కాకుండా
                వేరే మూలం నుండి స్వీకరించే పార్టీ ద్వారా సరిగ్గా పొందబడుతుంది.
              </li>
              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i>ఈ సేవా నిబంధనలలోని బాధ్యతలను
                నిర్వర్తించడానికి మాత్రమే మీరు మరియు ఇ పరిషెవాగ్రీ ఇద్దరూ రహస్య
                సమాచారాన్ని ఉపయోగించాలి.
              </li>
              <li>
                <i class="flaticon-checked"></i>రహస్య సమాచారం రక్షించబడాలి మరియు
                గౌరవించబడాలి.
              </li>
            </ul>
            <h3 class="mt-4">6. బాధ్యత యొక్క పరిమితి</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>వర్తించే చట్టాల ద్వారా
                అనుమతించబడిన మేరకు, ఇపరిషెవా ప్రత్యక్ష, పరోక్ష, యాదృచ్ఛిక,
                ప్రత్యేక, పర్యవసానంగా లేదా ఆదర్శప్రాయమైన నష్టాలకు బాధ్యత
                వహించదని మీరు స్పష్టంగా అర్థం చేసుకున్నారు మరియు
                అంగీకరిస్తున్నారు, వీటిలో పరిమితం కాకుండా, లాభాల నష్టానికి
                నష్టాలు, సౌహార్దాలు , ఉపయోగం, డేటా లేదా సేవను ఉపయోగించలేకపోవడం
                వల్ల కలిగే ఇతర అసంపూర్తి నష్టాలు.
              </li>
              <li>
                <i class="flaticon-checked"></i>వర్తించే చట్టాల ద్వారా
                అనుమతించబడిన మేరకు, మా సైట్, మా సేవలు లేదా ఈ సేవా నిబంధనలకు
                సంబంధించి లేదా వాటితో సంబంధం లేకుండా లేదా కోల్పోయిన లాభాలకు లేదా
                ఏదైనా ప్రత్యేకమైన, యాదృచ్ఛిక లేదా పర్యవసానంగా జరిగే నష్టాలకు ఇ
                పరిషెవా లేదా మా సరఫరాదారులు బాధ్యత వహించరు. నిర్లక్ష్యం).
              </li>
              <li>
                <i class="flaticon-checked"></i> మీరు మీ సేవలను ఉపయోగించడం మీ
                స్వంత ప్రమాదంలో ఉంది. సేవలు ఎటువంటి వారంటీ లేదా షరతులు,
                ఎక్స్‌ప్రెస్, సూచించిన లేదా చట్టబద్ధమైన లేకుండా “ఉన్నట్లుగా”
                మరియు “అందుబాటులో ఉన్న” ప్రాతిపదికన అందించబడతాయి.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవలు నిరంతరాయంగా, సమయానుసారంగా,
                సురక్షితంగా లేదా దోష రహితంగా ఉంటాయని eParisheva హామీ ఇవ్వదు.
              </li>
              <li>
                <i class="flaticon-checked"></i>
                సేవల ఉపయోగం నుండి పొందగలిగే ఫలితాలు ఖచ్చితమైనవి లేదా నమ్మదగినవి
                అని eParisheva హామీ ఇవ్వదు
              </li>
              <li>
                <i class="flaticon-checked"></i>eParisheva యొక్క సేవలకు
                సంబంధించిన మీ పన్ను బాధ్యతలు లేదా బాధ్యతలకు eParisheva బాధ్యత
                వహించదు.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవల ద్వారా మీరు కొనుగోలు చేసిన
                లేదా పొందిన ఏదైనా ఉత్పత్తులు, సేవలు, సమాచారం లేదా ఇతర పదార్థాల
                నాణ్యత మీ అంచనాలను అందుకుంటుందని లేదా సేవల్లో ఏవైనా లోపాలు
                సరిదిద్దబడతాయని eParisheva హామీ ఇవ్వదు.
              </li>
              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i>మీరు చట్టాన్ని ఉల్లంఘించినా, ఈ
                ఒప్పందాన్ని ఉల్లంఘించినా లేదా మూడవ పక్షం హక్కులకు వ్యతిరేకంగా
                వెళ్ళినా మేము బాధ్యత వహించము, ప్రత్యేకించి మీరు కేసు వేస్తే.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవ “ఉన్నట్లే” కనుక దీనికి
                లోపాలు లేదా అంతరాయాలు ఉండవచ్చు మరియు మేము ఎటువంటి వారెంటీలు
                ఇవ్వము మరియు మా బాధ్యత పరిమితం.
              </li>
            </ul>

            <h3 class="mt-4">8. మేధో సంపత్తి మరియు కస్టమర్ కంటెంట్</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i>మీరు eParisheva సేవకు అందించే
                పదార్థాలపై మేధో సంపత్తి హక్కులను మేము క్లెయిమ్ చేయము. మీ
                eParisheva స్టోర్‌కు మీరు అప్‌లోడ్ చేసిన అన్ని పదార్థాలు మీదే.
                మీరు మీ ఖాతాను తొలగించడం ద్వారా ఎప్పుడైనా మీ eParisheva
                స్టోర్‌ను తొలగించవచ్చు.
              </li>
              <li>
                <i class="flaticon-checked"></i>మెటీరియల్స్ అప్‌లోడ్ చేయడం
                ద్వారా, మీరు అంగీకరిస్తున్నారు: (ఎ) మీరు మీ స్టోర్‌కు బహిరంగంగా
                పోస్ట్ చేసే మెటీరియల్‌లను ఇతర ఇంటర్నెట్ వినియోగదారులను చూడటానికి
                అనుమతించడం; (బి) eParisheva ని నిల్వ చేయడానికి అనుమతించడం, మరియు
                మెటీరియల్స్ విషయంలో మీరు బహిరంగంగా పోస్ట్ చేస్తే, మీ మెటీరియల్స్
                ప్రదర్శించండి మరియు వాడండి; మరియు (సి) eParisheva, ఎప్పుడైనా,
                దాని సేవకు సమర్పించిన అన్ని పదార్థాలను సమీక్షించి, తొలగించగలదు,
                అయినప్పటికీ eParisheva అలా చేయవలసిన బాధ్యత లేదు.
              </li>
              <li>
                <i class="flaticon-checked"></i>మీరు దుకాణానికి అప్‌లోడ్ చేసే
                అన్ని పదార్థాలపై యాజమాన్యాన్ని కలిగి ఉంటారు; అయితే, మీ స్టోర్‌ను
                పబ్లిక్‌గా చేయడం ద్వారా, మీరు మీ స్టోర్‌కు పబ్లిక్‌గా పోస్ట్
                చేసే మెటీరియల్‌లను చూడటానికి ఇతరులను అనుమతించాలని మీరు
                అంగీకరిస్తున్నారు. ఏదైనా వర్తించే చట్టాలు లేదా నిబంధనలతో
                మెటీరియల్స్ పాటించాల్సిన బాధ్యత మీపై ఉంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>సేవను ప్రోత్సహించడానికి మీ
                స్టోర్‌తో అనుబంధించబడిన పేర్లు, ట్రేడ్‌మార్క్‌లు, సేవా గుర్తులు
                మరియు లోగోలను ఉపయోగించడానికి ప్రత్యేకత లేని హక్కు మరియు
                లైసెన్స్‌ను eParisheva కలిగి ఉంటుంది.
              </li>
              <li>
                <h3>ఏమిటంటే</h3>
                <i class="flaticon-checked"></i>మీరు అప్‌లోడ్ చేసే ఏదైనా మీదే
                మరియు మీ బాధ్యత.
              </li>
            </ul>
            <h3 class="mt-4">9. రద్దు మరియు ముగింపు</h3>

            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> మీరు eParisheva మద్దతును
                సంప్రదించడం ద్వారా మరియు eParisheva యొక్క ప్రతిస్పందనలో మీకు
                సూచించిన నిర్దిష్ట సూచనలను అనుసరించడం ద్వారా ఎప్పుడైనా మీ ఖాతాను
                రద్దు చేయవచ్చు మరియు సేవా నిబంధనలను ముగించవచ్చు.
              </li>
              <li>2. ఏ కారణం చేతనైనా ఏ పార్టీ అయినా సేవలను ముగించిన తరువాత:</li>
              <li>
                1. eParisheva మీకు సేవలను అందించడం ఆపివేస్తుంది మరియు మీరు ఇకపై
                మీ ఖాతాను యాక్సెస్ చేయలేరు.
              </li>
              <li>
                2. సేవా నిబంధనలలో అందించకపోతే, మీకు ఎటువంటి రుసుము యొక్క వాపసు
                ఇవ్వబడదు.
              </li>
              <li>
                3. అటువంటి రద్దు చేసిన తేదీ ద్వారా మీ సేవలను ఉపయోగించడం కోసం
                eParisheva కు రావాల్సిన బకాయిలు వెంటనే చెల్లించబడతాయి మరియు
                పూర్తిగా చెల్లించబడతాయి.
              </li>
              <li>4. మీ స్టోర్ వెబ్‌సైట్ ఆఫ్‌లైన్‌లో తీసుకోబడుతుంది.</li>
              <li>
                <i class="flaticon-checked"></i> సేవ ముగిసిన తేదీలో, మీరు
                చెల్లించాల్సిన ఫీజులు ఏమైనా ఉంటే, మీరు ఇమెయిల్ ద్వారా ఒక తుది
                ఇన్వాయిస్ అందుకుంటారు. ఆ ఇన్‌వాయిస్ పూర్తిగా చెల్లించిన తర్వాత,
                మీకు మళ్లీ ఛార్జీ విధించబడదు.
              </li>
              <li>
                <i class="flaticon-checked"></i> ఏ సమయంలోనైనా (వర్తించే చట్టం
                ప్రకారం అవసరమైతే తప్ప), ఏ కారణం చేతనైనా, eParishevaService, సేవా
                నిబంధనలు మరియు / లేదా మీ ఖాతాను సవరించడానికి లేదా ముగించే హక్కు
                మాకు ఉంది. సేవా నిబంధనల రద్దు ముగింపు తేదీకి ముందు తలెత్తిన ఏ
                హక్కులు లేదా బాధ్యతలకు పక్షపాతం లేకుండా ఉండాలి.
              </li>
              <li>
                <i class="flaticon-checked"></i>మోసం: ఇతర పరిష్కారాలను పరిమితం
                చేయకుండా, మీరు (నమ్మకం, పరిష్కారం, భీమా లేదా ఎస్క్రో దర్యాప్తు
                ద్వారా లేదా ఇతరత్రా) సేవల వాడకానికి సంబంధించి మోసపూరిత
                కార్యకలాపాలకు పాల్పడ్డారని మేము అనుమానించినట్లయితే,
                eParishevamay మీ ఖాతాను నిలిపివేయండి లేదా ముగించండి.
              </li>
              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i> ముగింపు సంప్రదింపు మద్దతును
                ప్రారంభించడానికి. eParishevawill మీ ఖాతా యొక్క ముగింపు
                ప్రక్రియకు సంబంధించిన నిర్దిష్ట సమాచారంతో ప్రతిస్పందించండి.
                ముగింపు నిర్ధారించబడిన తర్వాత, eParishevawill ద్వారా కొనుగోలు
                చేసిన డొమైన్‌లు ఇకపై స్వయంచాలకంగా పునరుద్ధరించబడవు. మీరు మీ
                బిల్లింగ్ చక్రం మధ్యలో రద్దు చేస్తే, మీకు చివరి ఇమెయిల్
                ఇన్‌వాయిస్ ఉంటుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i>మేము ఎప్పుడైనా మీ ఖాతాను
                మార్చవచ్చు లేదా ముగించవచ్చు. ఏదైనా మోసం మరియు మేము మీ ఖాతాను
                నిలిపివేస్తాము లేదా ముగించాము.
              </li>
            </ul>
            <h3 class="mt-4">10. సేవ మరియు ధరలకు మార్పులు</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> సేవలను ఉపయోగించటానికి ధరలు
                eParisheva నుండి 30 రోజుల నోటీసుపై మార్పు చెందుతాయి.
              </li>

              <li>
                <i class="flaticon-checked"></i> నోటీసుతో లేదా లేకుండా (వర్తించే
                చట్టం ప్రకారం అవసరమైతే తప్ప) సేవలను (లేదా దానిలోని ఏదైనా
                భాగాన్ని) సవరించడానికి లేదా నిలిపివేయడానికి eParisheva హక్కును
                ఎప్పటికప్పుడు కలిగి ఉంటుంది.
              </li>
              <li>
                <i class="flaticon-checked"></i> సేవ యొక్క ఏదైనా మార్పు, ధర
                మార్పు, సస్పెన్షన్ లేదా నిలిపివేత కోసం eParisheva మీకు లేదా మూడవ
                పక్షానికి బాధ్యత వహించదు.
              </li>

              <h3>ఏమిటంటే</h3>
              <li>
                <i class="flaticon-checked"></i> మేము బాధ్యత లేకుండా, ఎప్పుడైనా
                సేవను మార్చవచ్చు లేదా నిలిపివేయవచ్చు.
              </li>
            </ul>
            <h3 class="mt-4">11. గోప్యత & డేటా రక్షణ</h3>
            <ul class="eb-useing-gumtree">
              <li>
                <i class="flaticon-checked"></i> మీ వ్యక్తిగత సమాచారం యొక్క
                గోప్యతను మరియు మీ కస్టమర్ల వ్యక్తిగత సమాచారాన్ని రక్షించడానికి
                eParisheva గట్టిగా కట్టుబడి ఉంది. సేవను ఉపయోగించడం ద్వారా, ఈ
                వ్యక్తిగత సమాచారం యొక్క eParisheva యొక్క సేకరణ, వినియోగం మరియు
                బహిర్గతం మా గోప్యతా విధానం ద్వారా నిర్వహించబడుతుందని మీరు
                గుర్తించి, అంగీకరిస్తున్నారు.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      errors: [],
      selected: "ENGLISH",
      options: [
        { value: "ENGLISH", text: "English" },
        { value: "BANGLA", text: "Bangla" },
        { value: "TELAGU", text: "Telagu" },
      ],
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.policy-content .eb-useing-gumtree {
  position: relative;
}
.policy-content h3 {
  color: #533333;
  font-weight: 600;
}
.policy-content .eb-device-terms li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  margin-bottom: 10px;
  display: list-item;
  list-style: circle;
  margin-left: 1.4%;
}
.policy-content .eb-useing-gumtree li {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}
.policy-content .eb-useing-gumtree li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #828282;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.flaticon-checked:before {
  content: "✓";
}
</style>
